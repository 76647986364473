import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  naviagtionAction: {

    maxWidth: "12%",
        "& a": {
    },
  },

}));
