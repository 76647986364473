import React, { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";

import AppBar from "../../components/Layout/AppBar";
import WidgetCdbValid from "./WidgetCdbValid";
import { getStatusStatistiqueOfLogBook } from "../../services/api/backendApi";
import WidgetNbreCdb from "./WidgetNbreCdb";
import WidgetTheme from "./WidgetTheme";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        width: "auto",
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

const Statistic = () => {
  const [dataStatusCdb, setDataStatusCdb] = useState([]);
  React.useEffect(() => {
    getStatusStatistiqueOfLogBook((result) => {
      setDataStatusCdb(result);
    });
  }, []);

  return (
    <Container maxWidth="100%">
      <AppBar
        title="Carnet de bord : STATISTIQUES - ANIMATION RESEAU"
        color="primary"
        NotshowBrand
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Item sx={{ width: "32%" }}>
            <WidgetCdbValid data={dataStatusCdb} />
          </Item>
          <Item sx={{ width: "32%" }}>
            <WidgetNbreCdb />
          </Item>
          <Item sx={{ width: "32%" }}>
            <WidgetTheme />
          </Item>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Item sx={{ width: "65%", display: "none" }}>
            <WidgetCdbValid />
          </Item>
          <Item sx={{ width: "32%", display: "none" }}>
            <WidgetCdbValid />
          </Item>
        </Box>
      </div>
    </Container>
  );
};
export default Statistic;
