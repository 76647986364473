import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Divider from "@mui/material/Divider";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    // title: {
    //   display: true,
    //   text: "Carnets de bord validés au total (%)",
    //   position: "top",
    // },
    legend: {
      display: true, //Is the legend shown?
      position: "bottom", //Position of the legend.
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      width: "1px",
    },
  },
};
const labels = ["OPTIC2000", "LISSAC"];
const data = {
  labels,
  datasets: [],
};

const WidgetCdbValid = (props) => (
  <div style={{ width: "auto", height: "auto" }}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-around",
      }}
    >
      <span>Carnets de bord validés au total (%)</span>
    </div>
    <br />
    <Divider />
    <br />
    {props.data && props.data.length ? (
      <Bar options={options} data={{ ...data, datasets: props.data }} />
    ) : (
      <span>chargement...</span>
    )}
  </div>
);
export default WidgetCdbValid;
