import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  LinearProgress,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

import classes from "./ChapterCard.module.css";
import { useTheme } from "@mui/material/styles";
import { chapterPath } from "../../routes";

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 2,
        color: props.customcolor,
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="inherit" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">
          <strong>{`${Math.round(props.value)}%`}</strong>
        </Typography>
      </Box>
    </Box>
  );
}

const ChapterCard = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const id = props.data.id;
  const progressValue = props.progress
      ? (props.progress.current / props.progress.max) * 100
      : 0; // eslint-disable-next-line
  const {code, brand} = useParams();

  const showChapterHandler = useCallback(
      () => {
        navigate(`/home/${code}/${chapterPath}/${id}`);
      }, // eslint-disable-next-line
      [navigate, id]
  );
  return (
      <Card className={classes.chapter} elevation={4} data-cy="chapter-card">
        <CardActionArea onClick={showChapterHandler} data-cy="chapter-action-area">
          {props.disabled ? (
              <EditOffIcon
                  className={classes.cardIcon}
                  color={props.data.color}
                  data-cy="edit-off-icon"
              />
          ) : (
              <EditIcon
                  className={classes.cardIcon}
                  color={props.data.color}
                  data-cy="edit-icon"
              />
          )}
          <CardMedia
              component="img"
              image={props.data.image}
              className={classes.image}
              data-cy="chapter-image"
          />
          <CardContent
              sx={{backgroundColor: theme.palette[props.data.color].light}}
              data-cy="chapter-card-content"
          >
            <Typography
                variant="h5"
                component="div"
                className={classes.title}
                sx={{color: theme.palette[props.data.color].main}}
                data-cy="chapter-title"
            >
              {props.data.name}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                textAlign={"center"}
                data-cy="chapter-description"
            >
              {props.data.description}
            </Typography>
            <LinearProgressWithLabel
                variant="determinate"
                value={progressValue}
                customcolor={theme.palette[props.data.color].main}
                data-cy="chapter-progress"
            />
          </CardContent>
        </CardActionArea>
      </Card>
  );
}

  export default ChapterCard;
