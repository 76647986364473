import { useContext, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import {
  Box,
  IconButton,
  Button,
  Modal,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  Menu,
  Divider,
} from "@mui/material";
import DefaultAppBar from "@mui/material/AppBar";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import classes from "./AppBar.module.css";
import LogbookActivityContext from "../../context/logbookActivity-context";
import StorefrontIcon from "@mui/icons-material/Storefront";
import StatistiqueIcon from "@mui/icons-material/BarChart";
import LogbookFormContext from "../../context/logbookForm-context";
import MenuIcon from "@mui/icons-material/Menu";
import { actionplanPath, chapterPath, statisticPath } from "../../routes";
import { getMagasinByCode } from "../../services/api/backendApi";
import { useCookies } from "react-cookie";
import { OPTIC2OOO_BRAND, LISSAC_BRAND, getYear } from "../../tools";
import { useUser } from "../../hooks/useUser";

const AppBar = ({ title, color, NotshowBrand = false }) => {
  const LOGOUT_URL = `${process.env.REACT_APP_BACKEND_URL}/logout`;
  const [raisonSocial, setRaisonSocial] = useState("");
  const { chapter, brand, handleYear } = useContext(LogbookFormContext);
  const [chapterData, setchapterData] = useState();
  const { code } = useParams();
  const location = useLocation();
  const navigate = useNavigate(); // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["token"]);
  const currentYear = parseInt(getYear(), 10);
  const [year, setSelectedYear] = useState(currentYear);

  // icon & title

  const isChapter = location.pathname.includes("/chapter/");
  const isMatchRoutes = useRoutes(
    [{ path: `/${actionplanPath}/*` }, { path: `/${chapterPath}/*` }],
    location
  );

  if (isChapter) {
    const chapter = chapterData?.chapters?.find((chapterData) =>
      location.pathname.includes(`/chapter/${chapterData.id}`)
    );
    if (chapter) {
      color = chapter.color;
      title = chapter.name;
    }
  }

  // save modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activityProgress, validateActivity, activity } = useContext(
    LogbookActivityContext
  );
  const overallProgress = Object.keys(activityProgress).reduce(
    (acc, questionId) => {
      acc.max += activityProgress[questionId].max;
      acc.current += activityProgress[questionId].current;
      return acc;
    },
    { max: 0, current: 0 }
  );
  const isLookbookCompleted = overallProgress.current === overallProgress.max;
  const openModalHandler = () => setIsModalOpen(true);
  const closeModalHandler = () => setIsModalOpen(false);
  const confirmActivityValidationHandler = () => {
    validateActivity();
    closeModalHandler();
  };
  const clearAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  };
  const handleLogout = () => {
    clearAllCookies();
    window.location.replace(LOGOUT_URL);
  };

  const handleRetour = () => {
    navigate(`/home/${code}`);
  };
  const handleInfo = () => {
    if (brand === OPTIC2OOO_BRAND) {
      navigate(`/introOptic2000/${code}`);
    } else if (brand === LISSAC_BRAND) {
      navigate(`/introLissac/${code}`);
    }
  };
  const handleStore = () => {
    navigate("/brandselection");
    localStorage.removeItem("brand");
  };

  const naviagteToStatisticPage = () => {
    navigate(statisticPath);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: user } = useUser();

  useEffect(() => {
    getMagasinByCode(code)
      .then((data) => {
        setRaisonSocial(data.raisonSociale);
        setchapterData(chapter);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération du magasin:", error);
        setRaisonSocial("Erreur de chargement");
      });
    handleYear(year);
  }, [code, year]);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
    handleYear(selectedYear);
  };

  const yearOptions = [];
  for (let i = currentYear; i >= 2023; i--) {
    yearOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
      <DefaultAppBar position="fixed" color={color} data-cy="app-bar">
        <Toolbar className={classes.toolbar} data-cy="toolbar">
          {!!isMatchRoutes && (
              <IconButton
                  onClick={handleRetour}
                  aria-label="Retour"
                  title="Retour"
                  color="inherit"
                  data-cy="accueil-button"
              >
                <ArrowBackIosIcon />
              </IconButton>
          )}
          {!isMatchRoutes && (
              <>
                <IconButton
                    style={{ color: "white" }}
                    onClick={handleClick}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    title="Menu"
                    data-cy="menu-button"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 132,
                          height: 132,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 202,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    data-cy="account-menu"
                >
                  <MenuItem
                      onClick={handleStore}
                      aria-label="Store"
                      title="Selection magasin"
                      style={{ color: "#8b96bd" }}
                      data-cy="select-store-menu-item"
                  >
                    <StorefrontIcon sx={{ marginInlineEnd: "10px" }} /> Sélection
                    Magasin
                  </MenuItem>
                  {user?.organization === "STRUCTURE" && (
                      <MenuItem
                          onClick={naviagteToStatisticPage}
                          aria-label="statistique"
                          title="Statistique"
                          style={{ color: "#8b96bd" }}
                          data-cy="statistic-menu-item"
                      >
                        <StatistiqueIcon sx={{ marginInlineEnd: "10px" }} />
                        Statistique
                      </MenuItem>
                  )}
                  <MenuItem
                      onClick={handleInfo}
                      aria-label="Info"
                      title="Info"
                      style={{ color: "#8b96bd" }}
                      data-cy="info-menu-item"
                  >
                    <InfoIcon
                        sx={{
                          marginInlineEnd: "10px",
                        }}
                    />{" "}
                    Infos
                  </MenuItem>
                  <Divider />
                  <MenuItem
                      aria-label="Déconnexion"
                      title="Déconnexion"
                      onClick={handleLogout}
                      style={{ color: "#8b96bd" }}
                      data-cy="logout-menu-item"
                  >
                    <LogoutIcon sx={{ marginInlineEnd: "10px" }} /> Déconnexion
                  </MenuItem>
                </Menu>
                {!NotshowBrand && (
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        className={classes.appTitleEnseigne}
                        title="L'enseigne"
                        data-cy="brand-name"
                    >
                      {brand}
                    </Typography>
                )}
              </>
          )}
          <Typography
              variant="h6"
              noWrap
              component="div"
              className={classes.appTitle}
              data-cy="app-title"
          >
            {raisonSocial && code
                ? `${title} : ${raisonSocial} - ${code}`
                : title}
          </Typography>
          <Select
              className={`${classes.appAnnee} ${classes.fixedSelect}`}
              style={{ color: "#8b96bd" }}
              native
              value={year}
              onChange={handleYearChange}
              title="Sélectionner l'année souhaitée"
              data-cy="year-select"
          >
            {yearOptions}
          </Select>
          {!isMatchRoutes && (
              <>
                <IconButton
                    onClick={openModalHandler}
                    color="inherit"
                    title="Valider le carnet de bord"
                    data-cy="validate-logbook-button"
                >
                  <AssignmentTurnedInIcon />
                </IconButton>

                <Modal
                    open={isModalOpen}
                    onClose={closeModalHandler}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-cy="validation-modal"
                >
                  <Box className={classes.modal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Valider votre carnet de bord
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ my: 2 }}>
                      Êtes-vous sûr de vouloir valider définitivement votre carnet
                      de bord ? Une fois validé, il n&apos;est plus possible de le
                      modifier.
                    </Typography>
                    {!isLookbookCompleted && (
                        <Typography color="red" sx={{ fontStyle: "italic", my: 2 }}>
                          Il semble que vous n&apos;ayez pas répondu à
                          l&apos;intégralité des questions.
                        </Typography>
                    )}
                    <Box className={classes.modalActions}>
                      <Button
                          variant="contained"
                          onClick={confirmActivityValidationHandler}
                          data-cy="confirm-validation-button"
                      >
                        Confirmer
                      </Button>
                      <Button
                          variant="contained"
                          onClick={closeModalHandler}
                          data-cy="cancel-validation-button"
                      >
                        Annuler
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </>
          )}
        </Toolbar>
      </DefaultAppBar>
  );
}

export default AppBar;
