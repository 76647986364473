// TODO : use models when properly defined
const qualityChapter = {
    id: "quality",
    name: "Qualité",
    description: "",
    image: "/images/chapter_quality.png",
    icon: "workspacePremium",
    progress: 30,
    color: "pink",
    position: "IV",
    form: {
        pages: [
            {
                "elements": [
                    {
                        "type": "matrix",
                        "name": "quality_crm",
                        "title": "Gestion de la relation client",
                        "columns": [
                            {
                                "value": 1,
                                "text": "Oui"
                            }, {
                                "value": 0,
                                "text": "Non"
                            }
                        ],
                        "rows": [
                            {
                                "value": "quality_crm_nps",
                                "text": "Mon taux de satisfaction clients est > ou = à la moyenne nationale (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2011-ETUDE%20MIROIR.pdf?Open' target='_blank'>Fiche outil n°11</a>)"
                            },
                            {
                                "value": "quality_crm_optout",
                                "text": "Je sais gérer une demande de refus de communication d’un client ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%209-%20RGPD.pdf?Open' target='_blank'>Fiche outil n°9</a>)"
                            },
                            {
                                "value": "quality_crm_noClaim",
                                "text": "Aucune alerte de réclamation ne m’a été adressée"
                            },
                            {
                                "value": "quality_crm_allClaims",
                                "text": "Toutes les réclamations ont été traitées par le responsable et consignées dans un cahier prévu à cet effet (AFNOR) (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%205-AFNOR.pdf?Open' target='_blank'>Fiche outil n°5</a>)"
                            },
                            {
                                "value": "quality_crm_socialReviews",
                                "text": "Je gère mes avis clients "
                            },
                            {
                                "value": "quality_crm_CRITIZ",
                                "text": "J'utilise régulièrement la plateforme CRITIZ'R ?"
                            },
                            {
                                "value": "quality_crm_action",
                                "text": "Suite aux résultats je mets en place des plans d'actions ?"
                            }
                        ]
                    }
                ]
            },{
                "elements": [
                    {
                        "type": "matrix",
                        "name": "quality_window",
                        "title": "Gestion de l'image magasin",
                        "columns": [
                            {
                                "value": 1,
                                "text": "Oui"
                            }, {
                                "value": 0,
                                "text": "Non"
                            }
                        ],
                        "rows": [
                            {
                                "value": "quality_window_social",
                                "text": "Mes vitrines réseaux sociaux et internet sont elles à jour ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2010-LE%20DIGITAL.pdf?Open' target='_blank'>Fiche outil n°10</a>)"
                            },
                            {
                                "value": "quality_window_clean",
                                "text": "Mes vitrines sont-elles éclairées et propres ?"
                            },
                            {
                                "value": "quality_window_clearMessage",
                                "text": "Mes vitrines ont elles toutes un message clair et unique ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2013-POINT%20DE%20CONTROLE%20VITRINE.pdf?Open' target='_blank'>Fiche outil n°13</a>)"
                            },
                            {
                                "value": "quality_window_nationalComPlan",
                                "text": "Mes vitrines sont le relais de la communication nationale ?"
                            },
                            {
                                "value": "quality_window_personalComPlan",
                                "text": "Mes vitrines sont le relais de mon plan de communication ?"
                            },
                            {
                                "value": "quality_window_localComPlan",
                                "text": "Mes vitrines sont le relais de mes opérations en local ?"
                            },
                            {
                                "value": "quality_window_insurances",
                                "text": "Mes vitrines présentent les Ocams, les mutuelles et l’AFNOR ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2012-POINT%20DE%20CONTROLE%20BASIQUE%20QUALITE.pdf?Open' target='_blank'>Fiche outil n°12</a>)"
                            },
                            {
                                "value": "quality_window_hours",
                                "text": "Mes vitrines indiquent lisiblement les jours et horaires d’ouverture et de fermeture du magasin ?"
                            }
                        ]
                    }
                ]
            },
            {
                "elements": [
                    {
                        "type": "matrixdynamic",
                        "name": "quality_actionPlan",
                        "title": "Plan d'actions",
                        "addRowText": "Ajouter une action",
                        "columns": [
                            {
                                "name": "quality_action_what",
                                "title": "Quoi",
                                "cellType": "text"
                            }, {
                                "name": "quality_action_how",
                                "title": "Comment",
                                "cellType": "text"
                            }, {
                                "name": "quality_action_who",
                                "title": "Qui",
                                "cellType": "dropdown"
                            }, {
                                "name": "quality_action_when",
                                "title": "Quand",
                                "cellType": "text",
                                "inputType": "date"
                            }
                        ],
                        "detailElements": [
                            {
                                "name": "quality_action_kpi",
                                "title": "Indicateur de suivi",
                                "type": "text",
                                "startWithNewLine": false
                            }
                        ],
                        "detailPanelMode": "underRow",
                        "rowCount": 1
                    }
                ]
            },
            {
                "elements": [
                    {
                        "type": "comment",
                        "name": "quality_notes",
                        "title": "Notes"
                    }
                ]
            }
        ]
    },
    toolLinks: [
        { name: "Catalogues de formations", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=CJVN2R31615930 "},
        { name: "Campus", url: "https://audioptic.elmg.net/outils/accueil/"},
        { name: "Rubrique RH du Portail", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9CRFC836533760&CacheId=CFZB7C"},
        { name: "FIDAL", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9E2KG489428244&CacheId=CFZB7C"},
        { name: "Grille EI", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=97BE5S63467154&CacheId=CFZB7C"},
        { name: "AFNOR", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9D9LA991832913&CacheId=CFZB7C"},
        { name: "Boîte à outils", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=CAND7Y13409934&CacheId=CFZB7C"},
        { name: "Votre AR", url: ""}
    ]
};

export default qualityChapter;