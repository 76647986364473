// TODO : use models when properly defined
export const shopChapterLissac = {
  id: "shop",
  name: "Commerce",
  description: "",
  image: "/images/chapter_shop.png",
  icon: "store",
  progress: 10,
  color: "green",
  position: "III",
  form: {
    pages: [
      {
        elements: [
          {
            type: "matrixdropdown",
            name: "shop_performances_galileo",
            title: "Performances du magasin",
            description:
              "Tableau de bord Galiléo & revue d’activité année 2022 (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%206-MAITRISER%20VOS%20INDICATEURS%20COMMERCIAUX.pdf?Open' target='_blank'>Fiche outil n°6</a>)",
            columns: [
              {
                name: "shop_performances_galileo_CA",
                title: "Chiffre d'affaires",
                cellType: "text",
              },
              {
                name: "shop_performances_galileo_billsNb",
                title: "Nombre de factures optique",
                cellType: "text",
              },
              {
                name: "shop_performances_galileo_taux_de_transformation_des_devisPart",
                title: " Taux de transformation des devis (%)",
                cellType: "text",
              },
              {
                name: "shop_performances_galileo_sunglassesPart",
                title: "Poids solaire sur CA",
                cellType: "text",
              },
              {
                name: "shop_performances_galileo_lensPart",
                title: "Poids de la contactologie sur le CA",
                cellType: "text",
              },
              {
                name: "shop_performances_galileo_ocamPart",
                title: "Poids des réseaux OCAM",
                cellType: "text",
              },
            ],
            rows: [
              {
                value: "shop_performances_galileo_yearN",
                text: "Magasin N",
              },
              {
                value: "shop_performances_galileo_yearN-1",
                text: "Evolution magasin N-1",
              },
              {
                value: "shop_performances_galileo_comparison",
                text: "Comparatif réseau",
              },
            ],
          },
          {
            type: "matrix",
            name: "shop_performances_activity",
            titleLocation: "hidden",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_performances_questions_kpi",
                text: "Chaque jour je contrôle mes indicateurs commerciaux",
              },
              {
                value: "shop_performances_questions_galileo",
                text: "Chaque mois j’analyse la structure de mon tableau de bord Galiléo",
              },
              {
                value: "shop_performances_questions_sunglassesComparison",
                text: "Le poids de la famille solaire est > ou = au national",
              },
              {
                value: "shop_performances_questions_lensComparison",
                text: "Le poids de la contacto est > ou = au national",
              },
              {
                value: "shop_performances_questions_ocamComparison",
                text: "Le poids des réseaux OCAM est > au national",
              },
            ],
          },
          {
            type: "matrix",
            name: "shop_performances_par_de_marche_O2",
            titleLocation: "hidden",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_performances_transformation_devis",
                text: "Mon taux de transformation de devis est > national ?",
              },
              {
                value: "shop_performances_poids_2nd_paire_national",
                text: " Mon poids de 2nd paire >1€ est > ou = au national ?",
              },
              {
                value: "shop_performances_prix_vente",
                text: " Mon prix de vente moyen est  > ou = au national ?",
              },
            ],
          },
          {
            type: "matrixdropdown",
            name: "shop_performances_taux",
            title: "Etat",
            titleLocation: "hidden",
            hideNumber: true,
            columns: [
              {
                name: "shop_stockManagement_part_de_marche",
                title: "%",
                cellType: "text",
                inputType: "number",
                min: 0,
                max: 100,
                width: "100px",
              },
            ],
            rows: [
              {
                value: "shop_stockManagement_taux_sante",
                text: "Mon taux de 100% Santé est de ?",
              },
              {
                value: "shop_stockManagement_part_de_marche_value",
                text: "Ma part de marché du magasin sur la zone est de ?",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrixdropdown",
            name: "shop_file_galileo",
            title: "Le fichier",
            description:
              "Passeport clients sur Galiléo (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%208-ANALYSE%20FICHIER%20CLIENTS.pdf?Open' target='_blank'>Fiche outil n°8</a>)",
            columns: [
              {
                name: "shop_file_galileo_name",
                title: "Nb de clients dans mon fichier",
                cellType: "text",
                width: "150px",
              },
              {
                name: "shop_file_galileo_attrition",
                title: "Attrition",
                cellType: "text",
              },
              {
                name: "shop_file_galileo_loyalty",
                title: "Fidélisation",
                cellType: "text",
              },
              {
                name: "shop_file_galileo_hiring",
                title: "Recrutement",
                cellType: "text",
              },
              {
                name: "shop_file_galileo_optin_mail",
                title: "Adressabilité Courrier",
                cellType: "text",
              },
              {
                name: "shop_file_galileo_optin_sms",
                title: "Adressabilité SMS",
                cellType: "text",
              },
              {
                name: "shop_file_galileo_optin_email",
                title: "Adressabilité Mail",
                cellType: "text",
              },
            ],
            rows: [
              {
                value: "shop_file_galileo_yearN",
                text: "Magasin N",
              },
              {
                value: "shop_file_galileo_yearN-1",
                text: "Evolution magasin N-1",
              },
              {
                value: "shop_file_galileo_comparison",
                text: "Comparatif réseau",
              },
            ],
          },
          {
            type: "matrix",
            name: "shop_file_activity",
            titleLocation: "hidden",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_file_questions_kpi",
                text: "Mon taux d’<dfn title='Taux des clients n’étant pas revenu acheter depuis plus de 3 ans'>attrition</dfn> est < ou = à la moyenne nationale",
              },
              {
                value: "shop_file_questions_galileo",
                text: "Mon taux de <dfn title='Taux de clients étant revenu dans les 12 derniers mois'>fidélisation</dfn> est > ou = à la moyenne nationale",
              },
              {
                value: "shop_file_questions_sunglassesComparison",
                text: "Mon taux de <dfn title='Taux de nouveaux clients sur les acheteurs des 12 derniers mois. Moyenne nationale sur la base d’un fichier de 5 ans'>recrutement</dfn> est > ou = à la moyenne nationale",
              },
              {
                value: "shop_file_questions_lensComparison",
                text: "Mon fichier clients augmente ?",
              },
              {
                value: "shop_file_questions_mailOptin",
                text: "Adressabilité Courrier est > ou = à la moyenne nationale",
              },
              {
                value: "shop_file_questions_smsOptin",
                text: "Adressabilité SMS est > ou = à la moyenne nationale",
              },
              {
                value: "shop_file_questions_emailOptin",
                text: "Adressabilité @ est > ou = à la moyenne nationale",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "panel",
            showNumber: true,
            name: "shop_stockManagement",
            title: "Gestion des achats",
            elements: [
              {
                type: "matrixdropdown",
                name: "shop_stockManagement_state",
                title: "Etat",
                titleLocation: "hidden",
                hideNumber: true,
                columns: [
                  {
                    name: "shop_stockManagement_valueDay",
                    title: "Jours",
                    cellType: "text",
                    inputType: "number",
                    min: 0,
                    width: "120px",
                  },
                  {
                    name: "shop_stockManagement_valuePercentCA",
                    title: "% de mon CA HT",
                    cellType: "text",
                    inputType: "number",
                    min: 0,
                    max: 1000000,
                    width: "150px",
                  },
                ],
                rows: [
                  {
                    value: "shop_stockManagement_value",
                    text: "Mon stock représente",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_stockManagement_misc",
                title: "Gestion",
                hideNumber: true,
                titleLocation: "hidden",
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_stockManagement_check",
                    text: "Je contrôle mon stock ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%207-GESTION%20DES%20STOCKS.pdf?Open' target='_blank'>Fiche outil n°7</a>)",
                  },
                  {
                    value: "shop_stockManagement_inventory",
                    text: "Je fais un inventaire régulier ?",
                  },
                  {
                    value: "shop_stockManagement_inventoryCategory",
                    text: "par famille /par fournisseur /top /flop ?",
                  },
                  {
                    value: "shop_stockManagement_budget",
                    text: "J’ai établi un budget d’achats sur l’année (Volume/valeur) ?",
                  },
                  {
                    value: "shop_stockManagement_mddRatio",
                    text: "Mon taux de Marques Passion est-il compris entre 15 et 20% ?",
                  },
                  {
                    value: "shop_stockManagement_Passion",
                    text: "Je gère mes retours/échanges de marques Passion ?",
                  },
                  {
                    value: "shop_stockManagement_shippingCheck",
                    text: "Je contrôle mes livraisons ?",
                  },
                  {
                    value: "shop_stockManagement_suppliersCheck",
                    text: "Je réalise un suivi régulier/animation équipe de mes préconisations verres ?",
                  },
                  {
                    value: "shop_stockManagement_classicSAM",
                    text: "J’ai mis en place la SAM Classique ?",
                  },
                  {
                    value: "shop_stockManagement_goldSAM",
                    text: "J’ai mis en place la SAM Gold ?",
                  },
                  {
                    value: "shop_stockManagement_fournisseurs",
                    text: "Je consulte le planning des opérations fournisseurs ?",
                  },
                  {
                    value: "shop_stockManagement_montage",
                    text: "J'utilise l'atelier de montage de Clamart ?",
                  },
                  {
                    value: "shop_stockManagement_centrale",
                    text: "Mon taux de fidélité achats à la centrale est > ou = 75% ?",
                  },
                  {
                    value: "shop_stockManagement_club",
                    text: "J'ai pris connaissance du club des créateurs ?",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrix",
            name: "shop_finance",
            title: "Finance",
            description:
              "(<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%203-ANALYSE%20FINANCIERE.pdf?Open' target='_blank'>Fiche outil n°3</a>)",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_finance_balanceSheet",
                text: "Je réalise une analyse de mon bilan comptable et de son évolution ?",
              },
              {
                value: "shop_finance_profitAnsLossAccount",
                text: "Je réalise une analyse de mon compte de résultats et de son évolution ?",
              },
              {
                value: "shop_finance_actionPlan",
                text: "Je réalise un plan d’actions avec mon cabinet comptable ?",
              },
              {
                value: "shop_finance_bankingCheck",
                text: "Je réalise un point annuel avec mon banquier ou organisme de financement ?",
              },
              {
                value: "shop_finance_exploitation",
                text: "Mon taux EBE (excédent brut d'exploitation) est > ou = 12% ?",
              },
              {
                value: "shop_finance_bilan",
                text: "J'ai envoyé mon bilan à la centrale ?",
              },
              {
                value: "shop_finance_Synergee",
                text: "J'ai pris connaissance des moyennes nationales sur Synergee ?",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "panel",
            showNumber: true,
            name: "shop_CAImproveTools",
            title: "Outils de dynamisation du CA",
            elements: [
              {
                type: "matrix",
                name: "shop_CAImproveTools_communication",
                title: "Communication",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_CAImproveTools_communication_plan",
                    text: "J’ai réalisé mon plan de communication 2023 ?",
                  },
                  {
                    value: "shop_CAImproveTools_communication_animations",
                    text: "Je réalise des animations en local (Radio / Presse / supports locaux) ?",
                  },
                  {
                    value: "shop_CAImproveTools_communication_website",
                    text: "Je mets à jour régulièrement mon site opticien, mon application Contact ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%2010-LE%20DIGITAL.pdf?Open' target='_blank'>Fiche outil n°10</a>)",
                  },
                  {
                    value: "shop_CAImproveTools_communication_totem",
                    text: "Je mets à jour régulièrement mon totem digital via Zebrix ?",
                  },
                  {
                    value: "shop_CAImproveTools_communication_social",
                    text: "J’anime mes réseaux sociaux ?",
                  },
                  {
                    value: "shop_CAImproveTools_vitrophanies",
                    text: "Mes vitrophanies de communication nationale sont mises en place ?",
                  },
                  {
                    value: "shop_CAImproveTools_vitrine",
                    text: "Mon totem double face est mis en place en vitrine ?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_CAImproveTools_journey",
                title: "Parcours clients",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_CAImproveTools_journey_booxi",
                    text: "J’ai mis en place la prise de rendez-vous ? (Booxi)",
                  },
                  {
                    value: "shop_CAImproveTools_journey_prescriptionRenewal",
                    text: "Je travaille le renouvellement d’ordonnances et je développe le CA ?",
                  },
                  {
                    value: "shop_CAImproveTools_journey_devis",
                    text: "Je relance mes devis ?",
                  },
                  {
                    value: "shop_CAImproveTools_journey_prémium",
                    text: "J'ai mis en place les garanties prémium ?",
                  },
                  {
                    value: "shop_CAImproveTools_journey_ONEY",
                    text: "J'ai mis en place ONEY ?",
                  },
                  {
                    value: "shop_CAImproveTools_journey_Lissac_family",
                    text: "J'ai mis en place le club Lissac Family ?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_CAImproveTools_Enfants",
                title: "Parcours enfants",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value:
                      "shop_CAImproveTools_Enfants_espace_enfant_identifié",
                    text: "Mon espace enfant est identifié au sein du magasin ?",
                  },
                  {
                    value: "shop_CAImproveTools_Enfants_Loustics",
                    text: "Est-ce que les montures Loustics sont mises en avant ?",
                  },
                  {
                    value: "shop_CAImproveTools_Enfants_vitrine_enfants",
                    text: "Je mets en place régulièrement une vitrine enfants ?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_CAImproveTools_Sport",
                title: "Parcours sport",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_CAImproveTools_Sport_espace_sport",
                    text: "J'ai mis en place un corner sport en magasin ?",
                  },
                  {
                    value: "shop_CAImproveTools_Sport_2nd_sport",
                    text: "Je propose une 2nd paire sport ?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_CAImproveTools_BV",
                title: "Basse Vision",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_CAImproveTools_BV_vision",
                    text: "Je développe la basse vision ?",
                  },
                  {
                    value: "shop_CAImproveTools_BV_materiel_necessaire",
                    text: "J'ai le matériel nécessaire ?",
                  },
                  {
                    value: "shop_CAImproveTools_BV_communique",
                    text: "Je communique ma spécialité à mes prescripteurs ?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_CAImproveTools_Sur-Mesure",
                title: "Sur-Mesure",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_CAImproveTools_Sur-Mesure_propose",
                    text: "Je propose le sur-mesure mode ?",
                  },
                  {
                    value: "shop_CAImproveTools_Sur-Mesure_medical",
                    text: "Je propose le sur-mesure médical ?",
                  },
                  {
                    value: "shop_CAImproveTools_Sur-Mesure_atelier",
                    text: "J'utilise l'atelier pour des réparations ou adaptation de montures ?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "shop_CAImproveTools_concept",
                title: "Concept magasin",
                hideNumber: true,
                columns: [
                  {
                    value: 1,
                    text: "Oui",
                  },
                  {
                    value: 0,
                    text: "Non",
                  },
                ],
                rows: [
                  {
                    value: "shop_CAImproveTools_concept_brand",
                    text: "Ma façade de magasin correspond à la mise à l’image de l’enseigne (logo 2015 ou logo 2022) ?",
                  },
                  {
                    value: "shop_CAImproveTools_concept_2015",
                    text: "Mon magasin est au concept 2015 ou Z-21 ?",
                  },
                  {
                    value: "shop_CAImproveTools_concept_thisYear",
                    text: "Ou va être mis au concept cette année ?",
                  },
                  {
                    value: "shop_CAImproveTools_concept_ZA1",
                    text: "Mon magasin est-il en zone d’attractivité n°1 ?",
                  },
                  {
                    value: "shop_CAImproveTools_concept_location1",
                    text: "Mon magasin est-il en emplacement n°1 ?",
                  },
                  {
                    value: "shop_CAImproveTools_concept_space",
                    text: "La surface de mon magasin est-elle suffisante pour développer mon business ?",
                  },
                  {
                    value: "shop_CAImproveTools_concept_audio",
                    text: "Je souhaite développer l’audio ?",
                  },

                  {
                    value: "shop_CAImproveTools_concept_expansion",
                    text: "Je souhaite acquérir un ou des nouveaux magasins (Optic 2ooo, Lissac et Gadol) ?",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrix",
            name: "shop_benchmark",
            title: "Veille concurrentielle",
            description:
              "(<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%2014-INTERET%20DE%20FAIRE%20UNE%20VEILLE%20CONCURRENTIELLE.pdf?Open' target='_blank'>Fiche outil n°14</a>)",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_benchmark_competitiveVisit",
                text: "Faites-vous régulièrement des visites concurrences ?",
              },
              {
                value: "shop_benchmark_competitiveArrival",
                text: "Des plans d’actions sont mis en place avant l’arrivée ou dès l’ouverture d’un concurrent ?",
              },
              {
                value: "shop_benchmark_competitiveStrategy",
                text: "Etes-vous au courant de la politique commerciale de vos concurrents ?",
              },
              {
                value: "shop_benchmark_competitiveBrief",
                text: "Briefez-vous votre équipe avant ou lors de l’arrivée d’un concurrent ?",
              },
              {
                value: "shop_benchmark_competitiveKnowledge",
                text: "Connaissez-vous les spécificités telles que la Basse Vision, l'enfant, le sport ... de vos concurrents ?",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrix",
            name: "shop_b2B",
            title: "Business BtoB",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_b2b_companies",
                text: "Je développe mon CA à travers les entreprises, les Comités d’Entreprises : offres commerciales, prévention, sécurité... ? (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%2017-TRAVAILLER%20SES%20RELATIONS%20BTOB%20.pdf?Open' target='_blank'>Fiche outil n°17</a>)",
              },
              {
                value: "shop_b2b_associations",
                text: "Je développe mon CA à travers les associations sportives, séniors, du commerce local, ... ?",
              },
              {
                value: "shop_b2b_relationship",
                text: "J’entretiens des relations avec mes prescripteurs ?",
              },
              {
                value: "shop_b2b_animations",
                text: "J’organise des animations avec mes agences locales (mutuelles, assureurs..) ?",
              },
              {
                value: "shop_b2b_statistiques",
                text: "je suis régulièrement mes statistiques prescripteurs ?",
              },
            ],
          },
          {
            type: "matrixdropdown",
            name: "shop_visite_annee",
            title: "visite_annee",
            titleLocation: "hidden",
            hideNumber: true,
            columns: [
              {
                name: "shop_nb_visite_annee",
                title: "visite(s)",
                cellType: "text",
                inputType: "number",
                min: 0,
                width: "100px",
              },
            ],
            rows: [
              {
                value: "shop_nb_visite_annee_lissac_visite",
                text: "Combien de prescripteurs ai-je visité cette année ?",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrix",
            name: "shop_homeSales",
            title: "Vente à domicile",
            description:
              "(<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CANCSP40822614/$File/FICHE%2018-LE%20DOMICILE.pdf?Open' target='_blank'>Fiche outil n°18</a>)",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "shop_homeSales_yes",
                text: "Je fais de la vente à domicile et je développe mon CA ?",
              },
              {
                value: "shop_homeSales_ehpad",
                text: "Je développe mon CA à travers les EHPAD, EHPA, les maisons sénioriales, les foyers, ... ?",
              },
              {
                value: "shop_homeSales_cosiumTracking",
                text: "J’identifie mes ventes à domicile dans Cosium en cochant la case« Déplacement de l’opticien à domicile suite à la demande client » ?",
              },
            ],
          },
          {
            type: "matrixdropdown",
            name: "shop_homeSales_CA",
            title: "Etat",
            titleLocation: "hidden",
            hideNumber: true,
            columns: [
              {
                name: "shop_homeSales_CA_value",
                title: "CA €",
                cellType: "text",
                inputType: "number",
                min: 0,
                width: "100px",
              },
            ],
            rows: [
              {
                value: "shop_homeSales_CA_value_domicile",
                text: "Mon poids CA domicile sur CA Total est de ?",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrixdynamic",
            name: "shop_actionPlan",
            title: "Plan d'actions",
            addRowText: "Ajouter une action",
            columns: [
              {
                name: "shop_action_what",
                title: "Quoi",
                cellType: "text",
              },
              {
                name: "shop_action_how",
                title: "Comment",
                cellType: "text",
              },
              {
                name: "shop_action_who",
                title: "Qui",
                cellType: "dropdown",
              },
              {
                name: "shop_action_when",
                title: "Quand",
                cellType: "text",
                inputType: "date",
              },
            ],
            detailElements: [
              {
                name: "shop_action_kpi",
                title: "Indicateur de suivi",
                type: "text",
                startWithNewLine: false,
              },
            ],
            detailPanelMode: "underRow",
            rowCount: 1,
          },
        ],
      },
      {
        elements: [
          {
            type: "comment",
            name: "shop_notes",
            title: "Notes",
          },
        ],
      },
    ],
  },
  toolLinks: [
    {
      name: "COSIUM",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/pilotagemagasin/list?&FolderId=AZHHTS85636529&CacheId=CFZB7C",
    },
    {
      name: "Galiléo",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac",
    },
    {
      name: "Plan de communication",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/operationsclients/content?&DocId=CKDJET54577706&CacheId=CM9DXP&DocumentType=news",
    },
    {
      name: "SIV / Expansion",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/pilotagemagasin/list?&FolderId=BTSHH279747768&CacheId=CM9DXP",
    },
    {
      name: "Votre délégué(e) santé",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/pilotagemagasin/list?&FolderId=AEVE6861021286&CacheId=CM9DXP",
    },
    {
      name: "Nouveau Business",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/operationsclients/list?&FolderId=AUGNKV94891279&CacheId=CM9DXP",
    },
    {
      name: "Synergee",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac",
    },
    {
      name: "Boîte à outils",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/pilotagemagasin/content?&DocId=CANCSP40822614&CacheId=CFZB7C",
    },
    {
      name: "Mon Planning Opérationnel",
      url: "https://monportail.audioptic.fr/wps/myportal/lissac/pilotagemagasin/content?&DocId=CANC8S14041657&CacheId=CM9DXP",
    },
    { name: "Votre AR", url: "" },
  ],
};
