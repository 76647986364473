import {
  Container,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./BrandSelection.module.css";
import _ from "lodash";
import { CodeMagasin } from "./constants/CodeMagasin";
import LogbookFormContext from "../../context/logbookForm-context";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getAllMagasins } from "../../services/api/backendApi";
import { LISSAC_BRAND, OPTIC2OOO_BRAND } from "../../tools";
import AppButton from "../../components/Layout/AppButton";
import { statisticPath } from "../../routes";
import { useUser } from "../../hooks/useUser";

const BrandSelection = () => {
  const [AllMagasinState, setAllMagasinState] = useState([]);
  const [brandsByCode, setBrandsByCode] = useState(new Map());
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [year, setYear] = useState("2024");
  const [brand, setBrand] = useState("");
  const [filteredBrand, setFilteredBrand] = useState("");

  const navigateToStatisticPage = () => {
    navigate(statisticPath);
  };

  const onSubmit = (event) => {
    const introChecked = localStorage.getItem("hasReadIntro");
    if (code === "" || brand === "" || year === "") {
      setError(true);
    } else {
      setError(false);
      if (introChecked) {
        navigate(`/home/${code}`);
      } else if (brand === OPTIC2OOO_BRAND) {
        navigate(`/introOptic2000/${code}`);
      } else {
        navigate(`/introLissac/${code}`);
      }
    }
  };

  const { handelBrand } = useContext(LogbookFormContext);

  const handleChange = (event) => {
    setCode(event.target.value);
    const newBrand = brandsByCode.get(event.target.value);
    setBrand(newBrand);
    handelBrand(newBrand);
  };

  const { data: user } = useUser();

  useEffect(() => {
    async function fetchDataFromApi() {
      const data = await getAllMagasins();
      const brandsMap = new Map();
      data.forEach((magasin) => {
        brandsMap.set(
            magasin.codeMagasinSAP,
            magasin.groupeClient === "Z3" ? OPTIC2OOO_BRAND : LISSAC_BRAND
        );
      });
      setBrandsByCode(brandsMap);
      setAllMagasinState(data);
    }

    fetchDataFromApi();
  }, [year]);

  const selectableMagasins = useMemo(
      () =>
          AllMagasinState.filter((mag) => {
            if (
                filteredBrand !== "" &&
                brandsByCode.get(mag.codeMagasinSAP) !== filteredBrand
            ) {
              return false;
            }
            return true;
          }),
      [AllMagasinState, filteredBrand]
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 400,
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
      <Container className={classes.containercss} data-cy="brand-selection-container">
        <img
            className={classes.imgBandeau}
            src="/images/carnet-de-bord-bandeau.svg"
            alt="Logo intro"
            data-cy="brand-selection-banner"
        />
        <img
            className={classes.img}
            src="/images/carnet-de-bord-appli.svg"
            alt="Logo intro"
            data-cy="brand-selection-logo"
        />

        <div className={classes.grid_n1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <p className={classes.fild} data-cy="brand-selection-label">
                Veuillez selectionnez l&apos;enseigne :
              </p>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth data-cy="brand-selection-form-control">
                <InputLabel id="demo-simple-select-label-enseigne">
                  Enseigne
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label-enseigne"
                    label="Enseigne"
                    native
                    onChange={(e) => {
                      setFilteredBrand(e.target.value);
                      setCode("");
                    }}
                    value={filteredBrand}
                    title="Enseigne"
                    data-cy="brand-selection-select"
                >
                  <option aria-label="None" value="" />
                  <option value={OPTIC2OOO_BRAND}>Optic2OOO</option>
                  <option value={LISSAC_BRAND}>Lissac</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <p className={classes.fild} data-cy="code-selection-label">
                Veuillez selectionnez le code magasin :
              </p>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth data-cy="code-selection-form-control">
                <TextField
                    id="demo-simple-select-label-code"
                    label="Code"
                    required
                    variant="outlined"
                    value={code}
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-input": { textTransform: "capitalize" },
                    }}
                    data-cy="code-selection-input"
                />
              </FormControl>
            </Grid>
            <Grid
                item
                xs={4}
                sx={{
                  marginTop: "2%",
                }}
                data-cy="store-selection-label-container"
            >
              Veuillez selectionnez le nom magasin :
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth data-cy="store-selection-form-control">
                <InputLabel required id="demo-simple-select-label-magasin">
                  Magasin
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label-magasin"
                    label="Magasin"
                    native
                    onChange={handleChange}
                    value={code}
                    data-cy="store-selection-select"
                >
                  {!_.isEmpty(AllMagasinState) ? (
                      <>
                        <option aria-label="None" value="" />
                        {CodeMagasin &&
                            CodeMagasin.filter((mag) =>
                                selectableMagasins.some((opts) =>
                                    _.startsWith(opts.codePostalMagasin, mag.code)
                                )
                            ).map((mag) => (
                                <optgroup
                                    label={`${mag.departement} ${mag.code}`}
                                    key={mag.code}
                                    data-cy={`store-selection-group-${mag.code}`}
                                >
                                  {selectableMagasins.map((opts) => {
                                    if (
                                        _.startsWith(opts.codePostalMagasin, mag.code)
                                    ) {
                                      return (
                                          <option
                                              value={opts.codeMagasinSAP}
                                              key={opts.id}
                                              data-cy={`store-selection-option-${opts.codeMagasinSAP}`}
                                          >
                                            {opts.codeMagasinSAP} - {opts.localiteMagasin}{" "}
                                            - {opts.raisonSociale}
                                          </option>
                                      );
                                    }
                                    return null;
                                  })}
                                </optgroup>
                            ))}
                      </>
                  ) : (
                      <optgroup disabled label="Chargement..." />
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.error_n1}>
              {error ? <label data-cy="error-message">veuillez vérifier les valeurs saisies </label> : ""}
            </Grid>
            <Grid item xs={12} className={classes.btn_ccdb}>
              {user?.organization === "STRUCTURE" && (
                  <AppButton
                      className={classes.btn_stats}
                      variant="contained"
                      onClick={navigateToStatisticPage}
                      title="Consulter Statistiques"
                      data-cy="statistics-button"
                  />
              )}
              <AppButton
                  className={classes.btn_n1}
                  variant="contained"
                  onClick={onSubmit}
                  title="Consulter Carnet De Bord"
                  data-cy="logbook-button"
              />
            </Grid>
          </Grid>
        </div>
      </Container>
  );
};

export default BrandSelection;
