import {kyHttpClient} from "../../utils/http-client";

/**
 * @typedef {object} User
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string[]} shopCodes
 */

/**
 *
 * @returns {Promise<User>}
 */
export const getUser = async () =>
    await kyHttpClient.get('user').json();