export const CodeMagasin = [
  { code: "01", departement: "Ain" },
  { code: "02", departement: "Aisne" },
  { code: "03", departement: "Allier" },
  { code: "04", departement: "Alpes de Haute-Provence" },
  { code: "05", departement: "Hautes-Alpes" },
  { code: "06", departement: "Alpes-Maritimes" },
  { code: "07", departement: "Ardêche	" },
  { code: "08", departement: "Ardennes" },
  { code: "09", departement: "Ariège" },
  { code: "10", departement: "Aube" },
  { code: "11", departement: "Aude" },
  { code: "12", departement: "Aveyron" },
  { code: "13", departement: "Bouches-du-Rhône" },
  { code: "14", departement: "Calvados" },
  { code: "15", departement: "Cantal" },
  { code: "16", departement: "Charente" },
  { code: "17", departement: "Charente-Maritime" },
  { code: "18", departement: "Cher" },
  { code: "19", departement: "Corrèze" },
  { code: "2A", departement: "Corse-du-Sud" },
  { code: "2B", departement: "Haute-Corse" },
  { code: "21", departement: "Côte-d'Or" },
  { code: "22", departement: "Côtes d'Armor" },
  { code: "23", departement: "Creuse" },
  { code: "24", departement: "Dordogne" },
  { code: "25", departement: "Doubs" },
  { code: "26", departement: "Drôme" },
  { code: "27", departement: "Eure" },
  { code: "28", departement: "Eure-et-Loir" },
  { code: "29", departement: "Finistère" },
  { code: "30", departement: "Gard" },
  { code: "31", departement: "Haute-Garonne	" },
  { code: "32", departement: "Gers" },
  { code: "33", departement: "Gironde" },
  { code: "34", departement: "Hérault" },
  { code: "35", departement: "Île-et-Vilaine" },
  { code: "36", departement: "Indre" },
  { code: "37", departement: "Indre-et-Loire" },
  { code: "38", departement: "Isère" },
  { code: "39", departement: "Jura" },
  { code: "40", departement: "Landes" },
  { code: "41", departement: "Loir-et-Cher" },
  { code: "42", departement: "Loire" },
  { code: "43", departement: "Haute-Loire" },
  { code: "44", departement: "Loire-Atlantique" },
  { code: "45", departement: "Loiret" },
  { code: "46", departement: "Lot" },
  { code: "47", departement: "Lot-et-Garonne" },
  { code: "48", departement: "Lozère" },
  { code: "49", departement: "Maine-et-Loire	" },
  { code: "50", departement: "Manche" },
  { code: "51", departement: "Marne" },
  { code: "52", departement: "Haute-Marne" },
  { code: "53", departement: "Mayenne" },
  { code: "54", departement: "Meurthe-et-Moselle" },
  { code: "55", departement: "Meuse" },
  { code: "56", departement: "Morbihan" },
  { code: "57", departement: "Moselle" },
  { code: "58", departement: "Nièvre" },
  { code: "59", departement: "Nord" },
  { code: "60", departement: "Oise" },
  { code: "61", departement: "Orne" },
  { code: "62", departement: "Pas-de-Calais	" },
  { code: "63", departement: "Puy-de-Dôme" },
  { code: "64", departement: "Pyrénées-Atlantiques" },
  { code: "65", departement: "Hautes-Pyrénées" },
  { code: "66", departement: "Pyrénées-Orientales" },
  { code: "67", departement: "Bas-Rhin" },
  { code: "68", departement: "Haut-Rhin" },
  { code: "69", departement: "Rhône" },
  { code: "70", departement: "Haute-Saône" },
  { code: "71", departement: "Saône-et-Loire" },
  { code: "72", departement: "Sarthe" },
  { code: "73", departement: "Savoie" },
  { code: "74", departement: "Haute-Savoie" },
  { code: "75", departement: "Paris" },
  { code: "76", departement: "Seine-Maritime" },
  { code: "77", departement: "Seine-et-Marne" },
  { code: "78", departement: "Yvelines" },
  { code: "79", departement: "Deux-Sèvres" },
  { code: "80", departement: "Somme" },
  { code: "81", departement: "Tarn" },
  { code: "82", departement: "Tarn-et-Garonne" },
  { code: "83", departement: "Var" },
  { code: "84", departement: "Vaucluse" },
  { code: "85", departement: "Vendée" },
  { code: "86", departement: "Vienne" },
  { code: "87", departement: "Haute-Vienne" },
  { code: "88", departement: "Vosges" },
  { code: "89", departement: "Yonne" },
  { code: "90", departement: "Territoire-de-Belfort" },
  { code: "91", departement: "Essonne" },
  { code: "92", departement: "Hauts-de-Seine" },
  { code: "93", departement: "Seine-Saint-Denis" },
  { code: "94", departement: "Val-de-Marne" },
  { code: "95", departement: "Val-d'Oise" },
];
