import { Button, MobileStepper } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SportsScoreIcon from '@mui/icons-material/SportsScore';

import classes from "./ChapterNavigation.module.css";

const ChapterNavigation = (props) => {
    const theme = useTheme();
    const cssColor = theme.palette[props.color].main;
    const isLastStep = props.activeStep === props.steps.length - 1;
    const isFirstStep = props.activeStep === 0;
    return (
        <MobileStepper
            className={classes.chapterNavigationContainer}
            sx={{ color: cssColor }}
            variant="text"
            steps={props.steps.length}
            position="static"
            activeStep={props.activeStep}
            nextButton={
                <Button
                    variant="contained"
                    color={props.color}
                    onClick={isLastStep ? props.onCompleteChapterClick : props.onNextStepClick}
                    endIcon={isLastStep ? <SportsScoreIcon /> : <ArrowForwardIosIcon />}
                    aria-label={isLastStep ? "Terminer" : "Suivant"}
                    data-cy="next-button"
                    sx={{
                        ":hover": {
                            bgcolor: props.color + ".light",
                            color: props.color + ".main",
                        },
                    }}
                >
                    {isLastStep ? "Terminer" : "Suivant"}
                </Button>
            }
            backButton={
                <Button
                    variant="contained"
                    color={props.color}
                    onClick={props.onPreviousStepClick}
                    startIcon={<ArrowBackIosIcon />}
                    disabled={isFirstStep}
                    aria-label="Précédent"
                    data-cy="back-button"
                    sx={{
                        ":hover": {
                            bgcolor: props.color + ".light",
                            color: props.color + ".main",
                        },
                    }}
                >
                    Précédent
                </Button>
            }
            data-cy="chapter-navigation"
        />
    );
};

export default ChapterNavigation;
