import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";

import {
  getLogbookActivity,
  updateLogbookActivity,
  validateLogbookActivity,
} from "../services/api/backendApi";

import LogbookFormContext from "./logbookForm-context";
import { getThemePercent, isCalculatedRow } from "../tools";

const LogbookActivityContext = createContext({
  isLoading: false,
  error: null,
  activity: null,
  activityProgress: null,
  validateActivity: () => {},
  fetchData: () => {},
});

export const LogbookActivityContextProvider = (props) => {
  const {
    surveyVersion,
    surveyModel,
    updateSurveyData,
    setSurveyReadOnly,
    subscribeOnValueChanged,
    year,
  } = useContext(LogbookFormContext);
  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activity, setActivity] = useState(null);
  const [activityProgress, setActivityProgress] = useState({});

  const savePartialLogbookActivity = useCallback(
    (survey, options) => {
      const questionId = options.name;
      const answer = options.value;
      setActivity((prevActivity) => {
        const updatedActivity = { ...prevActivity };
        const subActivity = {
          questionId,
          answer,
        };
        if (!updatedActivity.answers) updatedActivity.answers = [];
        const existingAnswerIndex = updatedActivity.answers.findIndex(
          (answer) => answer.questionId === subActivity.questionId
        );
        if (existingAnswerIndex !== -1) {
          updatedActivity.answers[existingAnswerIndex] = {
            questionId: subActivity.questionId,
            answer: subActivity.answer,
          };
        } else {
          updatedActivity.answers.push({
            questionId: subActivity.questionId,
            answer: subActivity.answer,
          });
        }
        updateLogbookActivity(
          surveyVersion,
          code,
          year,
          subActivity,
          getThemePercent(surveyModel)
        );
        return updatedActivity;
      });

      setActivityProgress((prevProgressActivity) => {
        const updatedProgressActivity = { ...prevProgressActivity };
        const question = surveyModel.getQuestionByName(questionId);
        updatedProgressActivity[questionId] = {
          ...updatedProgressActivity[questionId],
          current: getQuestionProgress(question),
        };
        return updatedProgressActivity;
      });
    },
    [surveyVersion, surveyModel, code, year]
  );

  const getQuestionProgress = (question) =>
    question.getType() === "matrix"
      ? question.propertyHash.value
        ? Object.keys(question.propertyHash.value).length
        : 0
      : question.isEmpty()
      ? 0
      : 1;

  const initSurveyData = useCallback(
    (logbookActivity) => {
      const data = {};
      if (logbookActivity.answers) {
        logbookActivity.answers.forEach((answer) => {
          data[answer.questionId] = answer.answer;
        });
      }
      updateSurveyData(data);
    },
    [updateSurveyData]
  );

  const initActivityProgress = useCallback(() => {
    setActivityProgress((prevProgressActivity) => {
      const initActivityProgress = {};
      surveyModel.getAllQuestions().forEach((question) => {
        const questionId = question.propertyHash.name;
        const type = question.getType();
        if (isCalculatedRow(question)) {
          initActivityProgress[questionId] = {
            current: getQuestionProgress(question),
            max: type === "matrix" ? question.getRows().length : 1,
          };
        }
      });
      return initActivityProgress;
    });
  }, [surveyModel]);

  const validateActivity = async () => {
    try {
      const logbookActivity = await validateLogbookActivity(
        surveyVersion,
        code,
        year
      );
      setActivity(logbookActivity);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    setSurveyReadOnly(activity && activity.status === "VALIDATED");
  }, [activity, setSurveyReadOnly]);

  const fetchData = useCallback(
    async (code, year) => {
      try {
        setIsLoading(true);
        const logbookActivity = await getLogbookActivity(
          surveyVersion,
          code,
          year
        );
        if (logbookActivity.id) {
          setActivity(logbookActivity);
          subscribeOnValueChanged(savePartialLogbookActivity);
          initSurveyData(logbookActivity);
          initActivityProgress();
        } else {
          setActivity(null);
          const initialProgress = {};
          surveyModel.getAllQuestions().forEach((question) => {
            const questionId = question.propertyHash.name;
            initialProgress[questionId] = { max: 1, current: 0, percent: 0 };
          });
          setActivityProgress(initialProgress);
        }
        setActivity(logbookActivity);
        subscribeOnValueChanged(savePartialLogbookActivity);
        setIsLoading(false);
        setError(null);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    [
      surveyVersion,
      subscribeOnValueChanged,
      savePartialLogbookActivity,
      initSurveyData,
      initActivityProgress,
    ]
  );

  useEffect(() => {
    if (code && year) {
      fetchData(code, year);
    }
  }, [code, year, fetchData]);

  return (
    <LogbookActivityContext.Provider
      value={{
        isLoading,
        error,
        activity,
        activityProgress,
        validateActivity,
        fetchData,
      }}
    >
      {props.children}
    </LogbookActivityContext.Provider>
  );
};

export default LogbookActivityContext;
