import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useStyles from "./BottomStyle";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import Icon from "@material-ui/core/Icon";

const AppNavigation = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const currentTab = props.data.findIndex((element) =>
      location.pathname.startsWith(element.path)
  );
  const [selectedTab, setSelectedTab] = useState(currentTab);
  return (
      <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 100 }}
          elevation={3}
          data-cy="app-navigation"
      >
        <BottomNavigation
            showLabels
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            data-cy="bottom-navigation"
        >
          {props.data.map((element, index) => (
              <BottomNavigationAction
                  className={classes.naviagtionAction}
                  key={element.label}
                  sx={{ "&.Mui-selected": { color: `${element.color}.main` } }}
                  label={element.label}
                  icon={<Icon>{element.icon}</Icon>}
                  component={Link}
                  to={element.path}
                  data-cy={`navigation-action-${index}`}
              />
          ))}
        </BottomNavigation>
      </Paper>
  );
};

export default AppNavigation;
