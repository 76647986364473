import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Divider from "@mui/material/Divider";
import { NativeSelect } from "@mui/material";
import { getNumberOfLogBook } from "../../services/api/backendApi";
ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  cutout: 80,
  plugins: {
    legend: {
      display: true, //Is the legend shown?
      position: "bottom", //Position of the legend.
    },
  },
  responsive: true,
};
const labels = ["OPTIC2000", "LISSAC"];

const widgetData = {
  labels: [`Validé`, "En cours"],
  datasets: [
    {
      label: "%",
      data: [],
      backgroundColor: ["#5090A5", "#959595"],

      borderWidth: 1,
      spacing: 5,
    },
  ],
};
const WidgetNbreCdb = (props) => {
  const [enseigne, setEnseigne] = React.useState("OPTIC2000");
  const [dataState, setData] = React.useState(widgetData);
  const [result, setResult] = React.useState(widgetData);
  const handleChange = (event) => {
    setEnseigne(event.target.value);
  };

  React.useEffect(() => {
    getNumberOfLogBook(enseigne, (result) => {
      setResult(result);
      setData({
        ...dataState,
        labels: [
          `Validé (${result.validNumber})`,
          `En cours (${result.inProgressNumber})`,
        ],
        datasets: [
          {
            ...dataState.datasets[0],
            data: [result.validPercent, result.inProgressPercent],
          },
        ],
      });
    });
  }, [enseigne]);

  return (
    <div style={{ width: "auto", height: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-around",
        }}
      >
        <span>Carnets de bord par enseigne</span>
        <NativeSelect value={enseigne} onChange={handleChange} size="small">
          <option value="OPTIC2000">OPTIC2000</option>
          <option value="LISSAC">LISSAC</option>
          <option value="TOUTE ENSEIGNE">TOUTE ENSEIGNE</option>
        </NativeSelect>
      </div>
      <br />
      <Divider />
      <br />
      {dataState.datasets &&
      dataState.datasets[0] &&
      dataState.datasets[0].data &&
      dataState.datasets[0].data.length ? (
        <div
          style={{
            height: "300px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
            justifyContent: "space-around",
          }}
        >
          <Doughnut data={{ ...dataState }} options={options} />
          <div
            style={{
              height: "250px",
              width: "100px",
              paddingTop: "50px",

              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5>
              {result.sumCdb} /{result.cdbTotal} au total
            </h5>

            <h5>
              {parseFloat(result.cdbEnseignePercent).toFixed(3)}%
              d&apos;engagement
            </h5>
          </div>
        </div>
      ) : (
        <span>chargement...</span>
      )}
    </div>
  );
};
export default WidgetNbreCdb;
