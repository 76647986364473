import { Button, Stack } from "@mui/material";

import classes from "./ChapterToolbox.module.css";

const ChapterToolbox = (props) => (
  <Stack spacing={2}>
    {props.links.map((toolLink, index) => (
      <Button
        key={`toolLink_${index}`}
        href={toolLink.url}
        variant="contained"
        target="_blank"
        rel="noopener"
        className={classes.toolLink}
        color={props.color}
      >
        {toolLink.name}
      </Button>
    ))}
  </Stack>
);

export default ChapterToolbox;
