import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import NotFound from "./pages/NotFound/NotFound";
import Intro from "./pages/Intro/IntroLissac";
import IntroOptic2000 from "./pages/Intro/IntroOptic2000";
import Home from "./pages/Home/Home";
import Chapter from "./pages/Chapter/Chapter";
import classes from "./App.module.css";
import BrandSelection from "./pages/BrandSelection/BrandSelection";
import LogbookFormContext from "./context/logbookForm-context";
import { useContext } from "react";
import { ActionPlan } from "./pages/ActionPlan";

import {
  actionplanPath,
  brandPath,
  chapterPath,
  homePath,
  introOptic2000Path,
  introPath,
  loginPath,
  statisticPath,
} from "./routes";
import { Card } from "./pages/Card";
import Statistic from "./pages/Statistiques/Statistic";
import { useUser } from "./hooks/useUser";

const App = () => {
  const { chapter } = useContext(LogbookFormContext);
  const { data, isFetched } = useUser();

  return (
      <Box className={classes.mainContainer} data-cy="main-container">
        {data ? (
            <Routes data-cy="routes">
              <Route path={loginPath} element={<BrandSelection />} data-cy="route-brand-selection" />
              <Route path={brandPath} element={<BrandSelection />} data-cy="route-brand-selection" />
              <Route path={homePath} element={<Card />}>
                <Route index element={<Home />} data-cy="route-home" />
                {chapter.chapters.map((chapterData) => (
                    <Route
                        key={chapterData.id}
                        path={`${chapterPath}/${chapterData.id}/*`}
                        element={<Chapter data={chapterData} />}
                        data-cy={`route-chapter-${chapterData.id}`}
                    />
                ))}
                <Route path={actionplanPath} element={<ActionPlan />} data-cy="route-action-plan" />
              </Route>
              <Route path={introPath} element={<Intro />} data-cy="route-intro" />
              <Route path={introOptic2000Path} element={<IntroOptic2000 />} data-cy="route-intro-optic2000" />
              <Route path="*" element={<NotFound />} data-cy="route-not-found" />
              <Route path={statisticPath} element={<Statistic />} data-cy="route-statistic" />
            </Routes>
        ) : (
            <p data-cy="loading-text">Loading...</p>
        )}
      </Box>
  );

};

export default App;
