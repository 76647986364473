export const OPTIC2OOO_BRAND = "OPTIC2000";
export const LISSAC_BRAND = "LISSAC";

export const shop_file_galileo_comparison = {
  OPTIC2000: {
    2023: {
      shop_file_galileo_name: "NC",
      shop_file_galileo_attrition: "24%",
      shop_file_galileo_loyalty: "31%",
      shop_file_galileo_hiring: "42%",
      shop_file_galileo_optin_sms: "57%",
      shop_file_galileo_optin_mail: "62%",
      shop_file_galileo_optin_email: "24%",
    },
  },
  LISSAC: {
    2023: {
      shop_performances_galileo_CA: "-0.9%",
      shop_performances_galileo_billsNb: "NC",
      shop_performances_galileo_lensPart: "5.7%",
      shop_performances_galileo_ocamPart: "48%",
      shop_performances_galileo_sunglassesPart: "3.6%",
      shop_performances_galileo_taux_de_transformation_des_devisPart: "92.7%",
    },
  },
};

export const shop_performances_galileo_comparison = {
  OPTIC2000: {
    2023: {
      shop_performances_galileo_CA: "-0.7%",
      shop_performances_galileo_billsNb: "-2.3%",
      shop_performances_galileo_lensPart: "5.4%",
      shop_performances_galileo_ocamPart: "51.37%",
      shop_performances_galileo_sunglassesPart: "2.5%",
      shop_performances_galileo_taux_de_transformation_des_devisPart: "92%",
    },
  },
  LISSAC: {
    2023: {
      shop_file_galileo_name: "NC",
      shop_file_galileo_attrition: "24%",
      shop_file_galileo_loyalty: "31%",
      shop_file_galileo_hiring: "42%",
      shop_file_galileo_optin_sms: "65.8%",
      shop_file_galileo_optin_mail: "57.7%",
      shop_file_galileo_optin_email: "28.2%",
    },
  },
};

// eslint-disable-next-line import/no-unused-modules
export const getChapterVersion = (brand) => {
  if (brand === "LISSAC") {
    return "2022_DEV_V2";
  }
  return "2022_DEV_V1";
};

export const getYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear.toString();
};

export const calculTauxPercent = (value) => {
  let cdiPercent = 0;
  let opticienPercent = 0;
  const cdi = value.filter((item) => item.team_structure_permanent === true);
  const opticien = value.filter(
    (item) => item.team_structure_optician === true
  );
  if (cdi && cdi.length) {
    cdiPercent = (cdi.length * 100) / value.length;
  }
  if (opticien && opticien.length) {
    opticienPercent = (opticien.length * 100) / value.length;
  }
  return { cdiPercent, opticienPercent };
};

const getQuestionProgress = (question) =>
  // eslint-disable-next-line no-nested-ternary
  question.getType() === "matrix"
    ? question.propertyHash.value
      ? Object.keys(question.propertyHash.value).length
      : 0
    : question.isEmpty()
    ? 0
    : 1;
export const getThemePercent = (surveyModel) => {
  const activityProgress = {};
  surveyModel.getAllQuestions().forEach((question) => {
    const questionId = question.propertyHash.name;
    const type = question.getType();
    if (isCalculatedRow(question)) {
      const maxProgress = type === "matrix" ? question.getRows().length : 1;
      const currentProgress = getQuestionProgress(question);
      activityProgress[questionId] = {
        current: currentProgress,
        max: maxProgress,
      };
    }
  });
  const chapters = new Set();
  surveyModel.getAllQuestions().forEach((question) => {
    const questionId = question.propertyHash.name;
    const chapterId = questionId.split("_")[0];
    chapters.add(chapterId);
  });
  const chaptersProgress = {};
  chapters.forEach((chapterId) => {
    chaptersProgress[chapterId] = { max: 0, current: 0, percent: 0 };
  });
  Object.keys(activityProgress).forEach((questionId) => {
    const pathElements = questionId.split("_");
    if (pathElements.length === 0) return;
    const chapterId = pathElements[0];
    if (!chaptersProgress[chapterId]) {
      chaptersProgress[chapterId] = { max: 0, current: 0, percent: 0 };
    }
    chaptersProgress[chapterId].max += activityProgress[questionId].max;
    chaptersProgress[chapterId].current += activityProgress[questionId].current;
    chaptersProgress[chapterId].percent = Math.round(
      (chaptersProgress[chapterId].current / chaptersProgress[chapterId].max) *
        100
    );
  });
  Object.keys(chaptersProgress).forEach((chapterId) => {
    if (chaptersProgress[chapterId].max === 0) {
      chaptersProgress[chapterId].percent = 0;
    }
  });
  return chaptersProgress;
};

export const isCalculatedRow = (question) => {
  const questionPreRempli = ["shop_performances_galileo", "shop_file_galileo"];
  if (!questionPreRempli.includes(question.name)) return true;
  return false;
};
