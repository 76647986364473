import React from "react";
import Divider from "@mui/material/Divider";
import { NativeSelect } from "@mui/material";
import { getPercentThemeOfLogBook } from "../../services/api/backendApi";
import CardStats from "../../components/Layout/CardStats";
import Grid from "@mui/material/Grid";

const WidgetTheme = (props) => {
  const [enseigne, setEnseigne] = React.useState("OPTIC2000");
  const [result, setResult] = React.useState("");
  const handleChange = (event) => {
    setEnseigne(event.target.value);
  };

  React.useEffect(() => {
    getPercentThemeOfLogBook(enseigne, (result) => {
      setResult(result);
    });
  }, [enseigne]);

  return (
    <div style={{ width: "auto", height: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-around",
        }}
      >
        <span>Complétude par théme</span>
        <NativeSelect value={enseigne} onChange={handleChange} size="small">
          <option value="OPTIC2000">OPTIC2000</option>
          <option value="LISSAC">LISSAC</option>
          <option value="TOUTE ENSEIGNE">TOUTE ENSEIGNE</option>
        </NativeSelect>
      </div>
      <br />
      <Divider />

      <Grid container>
        <Grid item xs={6}>
          <CardStats
            title="L'équipe"
            color="#DBCFDF"
            value={result ? result.teamPercent : 0}
          />
        </Grid>
        <Grid item xs={6}>
          <CardStats
            title="Compétences managériales"
            color="#F1C296"
            value={result ? result.skillsPercent : 0}
          />
        </Grid>
        <Grid item xs={6}>
          <CardStats
            title="Commerce"
            color="#BCDDE1"
            value={result ? result.shopPercent : 0}
          />
        </Grid>
        <Grid item xs={6}>
          <CardStats
            title="Qualité"
            color="#E1D0BC"
            value={result ? result.qualityPercent : 0}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default WidgetTheme;
