import ky from "ky";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const kyHttpClient = ky.create({
  prefixUrl: BACKEND_URL,
  credentials: "include",
  timeout: 5000,
  hooks: {
    afterResponse: [
      (request, options, response) => {
        if (response.redirected) {
          window.location.href = response.url;
        }
        return response;
      },
    ],
  },
});
