import { createContext, useEffect, useState } from "react";
import * as Survey from "survey-react";
import teamChapter from "../data/logbook/optic2000/chapter_team";
import shopChapter from "../data/logbook/optic2000/chapter_shop";
import qualityChapter from "../data/logbook/optic2000/chapter_quality";
import skillsChapter from "../data/logbook/optic2000/chapter_skills";
import { qualityChapterLissac } from "../data/logbook/lissac/chapter_quality_lissac";
import { shopChapterLissac } from "../data/logbook/lissac/chapter_shop_lissac";
import { skillsChapterLissac } from "../data/logbook/lissac/chapter_skills__lissac";
import { teamChapterLissac } from "../data/logbook/lissac/chapter_team_lissac";
import { useParams } from "react-router-dom";
import {
  OPTIC2OOO_BRAND,
  LISSAC_BRAND,
  getChapterVersion,
  getYear,
  shop_file_galileo_comparison,
  shop_performances_galileo_comparison,
  calculTauxPercent,
} from "../tools";
import { useUser } from "../hooks/useUser";

const LogbookFormContext = createContext({
  surveyModel: null,
  chapter: null,
  handelCode: () => {},
  handleYear: () => {},
  handelBrand: () => {},
  brand: "",
  year: "",
});

export const LogbookFormContextProvider = (props) => {
  const { data: user } = useUser();
  const [brand, setBrand] = useState(localStorage.getItem("brand"));
  const [year, setYear] = useState();
  const { code } = useParams();
  const [chapter, setChapter] = useState({
    version: getChapterVersion(brand),
    chapters: [],
  });

  useEffect(() => {
    if (
      user?.organization === OPTIC2OOO_BRAND ||
      user?.organization === LISSAC_BRAND
    ) {
      setBrand(user.organization);
    }

    if (brand === LISSAC_BRAND) {
      setChapter({
        version: "2022_DEV_V2",
        chapters: [
          teamChapterLissac,
          skillsChapterLissac,
          shopChapterLissac,
          qualityChapterLissac,
        ],
      });
    } else {
      setChapter({
        version: "2022_DEV_V1",
        chapters: [teamChapter, skillsChapter, shopChapter, qualityChapter],
      });
    }
  }, [brand, user, year]);

  const surveyConfig = {
    triggers: [
      {
        type: "runexpression",
        expression: "{team_structure} notempty or {team_structure} empty",
        runExpression: `copyMatrixValues({team_structure}, "team_structure_member", [
                    ["team_skills", "team_skills_skillMember"],
                    ["team_actionPlan", "team_action_who"],
                    ["skills_actionPlan", "skills_action_who"],
                    ["quality_actionPlan", "quality_action_who"],
                    ["shop_actionPlan", "shop_action_who"]
                ])`,
      },
    ],
    pages: chapter.chapters.reduce((acc, chapter) => {
      chapter.form.pages.forEach(
        (page) => (page.visibleIf = `{currentChapter} = ${chapter.id}`)
      );
      acc = acc.concat(chapter.form.pages);
      return acc;
    }, []),
  };

  const surveyModel = new Survey.Model(surveyConfig);
  const copyMatrixValues = ([
    matrixOriginValues,
    matrixOriginColName,
    matrixTargets,
  ]) => {
    if (matrixOriginValues === null) return;
    const choices = matrixOriginValues.map((el) => ({
      value: el[matrixOriginColName],
    }));
    matrixTargets.forEach(([matrixRowTarget, matrixColTarget]) => {
      surveyModel
        .getQuestionByName(matrixRowTarget)
        .getColumnByName(matrixColTarget).choices = choices;
    });
  };
  Survey.FunctionFactory.Instance.register(
    "copyMatrixValues",
    copyMatrixValues
  );

  surveyModel.showTitle = false;
  surveyModel.locale = "fr";
  surveyModel.showNavigationButtons = false;
  surveyModel.showPageTitles = false;
  surveyModel.onTextMarkdown.add((survey, options) => {
    options.html = options.text;
  });

  surveyModel.onMatrixCellCreated.add((survey, options) => {
    if (options.row.name === "shop_performances_galileo_comparison") {
      const performanceRows = [
        "shop_performances_galileo_CA",
        "shop_performances_galileo_billsNb",
        "shop_performances_galileo_taux_de_transformation_des_devisPart",
        "shop_performances_galileo_sunglassesPart",
        "shop_performances_galileo_lensPart",
        "shop_performances_galileo_ocamPart",
      ];
      if (performanceRows.includes(options.columnName)) {
        options.cellQuestion.readOnly = true;
      }
    }

    if (options.row.name === "shop_file_galileo_comparison") {
      const fileRows = [
        "shop_file_galileo_name",
        "shop_file_galileo_attrition",
        "shop_file_galileo_loyalty",
        "shop_file_galileo_hiring",
        "shop_file_galileo_optin_mail",
        "shop_file_galileo_optin_sms",
        "shop_file_galileo_optin_email",
      ];
      if (fileRows.includes(options.columnName)) {
        options.cellQuestion.readOnly = true;
      }
    }

    if (
      options.row.name === "team_structure_tx_opticien" ||
      options.row.name === "team_structure_tx_cdi"
    ) {
      if (options.columnName === "team_structure_nbr") {
        options.cellQuestion.readOnly = true;
      }
    }
  });

  surveyModel.onValueChanging.add((sender, options) => {
    if (
      options.name === "team_structure" &&
      options.value &&
      options.value.length
    ) {
      const cdiPercent = calculTauxPercent(options.value)?.cdiPercent;
      const opticienPercent = calculTauxPercent(options.value)?.opticienPercent;
      surveyModel.data = {
        ...surveyModel.data,
        team_structure_tx_opticiens: {
          team_structure_tx_cdi: {
            team_structure_nbr: parseFloat(cdiPercent).toFixed(2),
          },
          team_structure_tx_opticien: {
            team_structure_nbr: parseFloat(opticienPercent).toFixed(2),
          },
        },
      };
    }
  });

  const updateSurveyData = (data) => {
    surveyModel.data = data;
    const cdiPercent = calculTauxPercent(
      surveyModel.data.team_structure
    )?.cdiPercent;
    const opticienPercent = calculTauxPercent(
      surveyModel.data.team_structure
    )?.opticienPercent;
    surveyModel.data = {
      ...data,
      team_structure_tx_opticiens: {
        team_structure_tx_cdi: {
          team_structure_nbr: parseFloat(cdiPercent).toFixed(2),
        },
        team_structure_tx_opticien: {
          team_structure_nbr: parseFloat(opticienPercent).toFixed(2),
        },
      },
      shop_file_galileo: {
        ...surveyModel.data.shop_file_galileo,
        shop_file_galileo_comparison: shop_file_galileo_comparison[brand][2023],
      },
      shop_performances_galileo: {
        ...surveyModel.data.shop_performances_galileo,
        shop_performances_galileo_comparison:
          shop_performances_galileo_comparison[brand][2023],
      },
    };
    surveyModel.runTriggers();
  };

  const setSurveyReadOnly = (isReadOnly) =>
    (surveyModel.mode = isReadOnly ? "display" : "edit");

  const subscribeOnValueChanged = (subscriber) =>
    surveyModel.onValueChanged.add(subscriber);
  const subscribeOnMatrixCellValueChanged = (subscriber) =>
    surveyModel.onMatrixCellValueChanged.add(subscriber);
  const subscribeOnMatrixCellValueChanging = (subscriber) =>
    surveyModel.onMatrixCellValueChanging.add(subscriber);

  return (
    <LogbookFormContext.Provider
      value={{
        surveyVersion: chapter.version,
        surveyModel,
        brand,
        chapter,
        handelBrand: (newBrand) => {
          setBrand(newBrand);
          localStorage.setItem("brand", newBrand);
        },
        handleYear: (surveyYear) => {
          setYear(surveyYear);
        },
        updateSurveyData,
        setSurveyReadOnly,
        subscribeOnValueChanged,
        subscribeOnMatrixCellValueChanged,
        subscribeOnMatrixCellValueChanging,
        code,
        year,
      }}
    >
      {props.children}
    </LogbookFormContext.Provider>
  );
};

export default LogbookFormContext;
