import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import App from "./App";

import { LogbookActivityContextProvider } from "./context/logbookActivity-context";
import { LogbookFormContextProvider } from "./context/logbookForm-context";
import { CookiesProvider } from "react-cookie";
import queryClient from "./utils/query-client";
import {QueryClientProvider} from "@tanstack/react-query";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8b96bd",
      light: "#c4cade",
      dark: "#727fae",
      contrastText: "#ffffff",
    },
    purple: {
      main: "#c59abb",
      light: "#efebf2",
      contrastText: "#ffffff",
    },
    orange: {
      main: "#f57553",
      light: "#feeae5",
      contrastText: "#ffffff",
    },
    green: {
      main: "#34bfbe",
      light: "#e1f5f5",
      contrastText: "#ffffff",
    },    
    pink: {
      main: "#ff618d",
      light: "#f5e1e7",
      contrastText: "#ffffff",
    },
  },
  // improve rendering
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

/*
    StyledEngineProvider => handles CSS injection order
    ThemeProvider => provides theme down the components tree thanks to the context
    CssBaseline => normalizes CSS
*/
ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <LogbookFormContextProvider>
              <LogbookActivityContextProvider>
                  <CookiesProvider>
                    <App />
                  </CookiesProvider>
              </LogbookActivityContextProvider>
            </LogbookFormContextProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>,
  document.getElementById("root")
);
