import { Stepper, Step, StepLabel } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import classes from "./ChapterStepper.module.css";

const ChapterStepper = (props) => {
    const theme = useTheme()
    const cssColor = theme.palette[props.color].main;
    return (
        <Stepper
            activeStep={props.activeStep}
            alternativeLabel
            data-cy="chapter-stepper"
        >
            {props.steps.map((step, index) => (
                <Step
                    key={"step_" + step.elements[0].name}
                    onClick={props.onStepClick.bind(null, index)}
                    sx={{ color: cssColor }}
                    completed={false}
                    className={classes.step}
                    data-cy={`step-${index}`}
                >
                    <StepLabel
                        className={classes.stepLabel}
                        sx={{ color: cssColor }}
                        classes={{
                            labelContainer: classes.stepLabelContainer,
                            label: classes.stepLabel,
                            active: classes.activeStepLabel,
                            disabled: classes.disabledStepLabel,
                        }}
                        data-cy={`step-label-${index}`}
                    >
                        {step.elements[0].title}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default ChapterStepper;
