// TODO : use models when properly defined
const teamChapter = {
  id: "team",
  name: "L'équipe",
  description: "",
  image: "/images/chapter_team.png",
  icon: "groups",
  progress: 45,
  color: "purple",
  position: "I",
  form: {
    pages: [
      {
        elements: [
          {
            type: "matrixdynamic",
            name: "team_structure",
            title: "Structure de l'équipe",
            addRowText: "Ajouter un collaborateur",
            columns: [
              {
                name: "team_structure_member",
                title: "Nom",
                cellType: "text",
                width: "100%",
                targetChoices: "skills_action_who",
              },
              {
                name: "team_structure_optician",
                title: "Opticien",
                cellType: "boolean",
                labelFalse: "Non",
                labelTrue: "Oui",
                maxWidth: "1px",
              },
              {
                name: "team_structure_permanent",
                title: "CDI",
                cellType: "boolean",
                labelFalse: "Non",
                labelTrue: "Oui",
                maxWidth: "1px",
              },
            ],
            rowCount: 1,
          },
          {
            type: "matrixdropdown",
            name: "team_structure_nbr_opticiens",
            title: "Etat",
            titleLocation: "hidden",
            hideNumber: true,
            columns: [
              {
                name: "team_structure_nbr",
                title: "Nbr",
                cellType: "text",
                inputType: "number",
                min: 0,
                max: 1000000,
                width: "150px",
              },
            ],
            rows: [
              {
                value: "team_structure_nbr_opticien",
                text: "Quel est le nombre d’opticiens ? ",
              },
              {
                value: "team_structure_nbr_cdi",
                text: "Quel est le nombre de CDI ? ",
              },
            ],
          },
          {
            type: "matrixdropdown",
            name: "team_structure_tx_opticiens",
            title: "Etat",
            titleLocation: "hidden",
            hideNumber: true,
            columns: [
              {
                name: "team_structure_nbr",
                title: "%",
                cellType: "text",
                inputType: "number",
                min: 0,
                max: 1000000,
                width: "150px",
              },
            ],
            rows: [
              {
                value: "team_structure_tx_opticien",
                text: "Quel est le taux d’opticiens ? ",
              },
              {
                value: "team_structure_tx_cdi",
                text: "Quel est le taux de CDI ? ",
              },
            ],
          },
          {
            type: "matrixdropdown",
            name: "team_structure_TTC_moyenne",
            title: "Etat",
            titleLocation: "hidden",
            hideNumber: true,
            columns: [
              {
                name: "team_structure_TTC",
                title: "€ TTC",
                cellType: "text",
                inputType: "number",
                min: 0,
                max: 1000000,
                width: "150px",
              },
            ],
            rows: [
              {
                value: "team_structure_TTC_collaborateur",
                text: "Quelle est ma productivité moyenne par collaborateur en € TTC ? ",
              },
              {
                value: "team_structure_collaborateur_CDD",
                text: "Quelle est le nombre de contrat à durée déterminée ? ",
              },
              {
                value: "team_structure_collaborateur_apprentissage",
                text: "Quelle est le nombre de contrat d'apprentissage  ?",
              },
            ],
          },
          {
            type: "matrix",
            name: "team_structure_collaborateur",
            titleLocation: "hidden",
            columns: [
              {
                value: 1,
                text: "Oui",
              },
              {
                value: 0,
                text: "Non",
              },
            ],
            rows: [
              {
                value: "team_structure_collaborateur_recrutement",
                text: "J'ai identifié et planifié mes besoins en recrutement ?",
              },
              {
                value: "team_structure_collaborateur_organisation",
                text: "J'ai identifié et planifié mes besoins en organisation magasin ?",
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: "matrixdynamic",
            name: "team_skills",
            title: "Plan de Formations de l'équipe ",
            description:
              "(<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2015-ENTRETIEN%20ANNUEL%20D%20EVALUATION.pdf?Open' target='_blank'>Fiche outil n°15</a>)",
            addRowText: "Ajouter une formation",
            horizontalScroll: true,
            columnMinWidth: "100px",
            columns: [
              {
                name: "team_skills_skillName",
                title: "Compétence",
                cellType: "dropdown",
                minWidth: "100%",
                choices: [
                  "Livre Blanc",
                  "Informatique",
                  "Vendeurs Chocs",
                  "Parcours non-Opticien",
                  "Communication et Vente",
                  "Technique et Métier",
                  "Management et Gestion",
                  "Bilan de compétences",
                ],
              },
              {
                name: "team_skills_skillMember",
                title: "Collaborateur",
                cellType: "dropdown",
              },
              {
                name: "team_skills_skillDate",
                title: "Date",
                cellType: "text",
                inputType: "date",
              },
            ],
            rowCount: 1,
          },
        ],
      },
      {
        elements: [
          {
            type: "matrixdynamic",
            name: "team_actionPlan",
            title: "Plan d'actions",
            addRowText: "Ajouter une action",
            columns: [
              {
                name: "team_action_what",
                title: "Quoi",
                cellType: "text",
              },
              {
                name: "team_action_how",
                title: "Comment",
                cellType: "text",
              },
              {
                name: "team_action_who",
                title: "Qui",
                cellType: "dropdown",
              },
              {
                name: "team_action_when",
                title: "Quand",
                cellType: "text",
                inputType: "date",
              },
            ],
            detailElements: [
              {
                name: "team_action_kpi",
                title: "Indicateur de suivi",
                type: "text",
                startWithNewLine: false,
              },
            ],
            detailPanelMode: "underRow",
            rowCount: 1,
          },
        ],
      },
      {
        elements: [
          {
            type: "comment",
            name: "team_notes",
            title: "Notes",
          },
        ],
      },
    ],
  },
  toolLinks: [
    {
      name: "Catalogues de formations",
      url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=CJVN2R31615930 ",
    },
    { name: "Campus", url: "https://audioptic.elmg.net/outils/accueil/" },
    {
      name: "Rubrique RH du portail",
      url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9CRFC836533760&CacheId=CFZB7C",
    },
    {
      name: "FIDAL",
      url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9E2KG489428244&CacheId=CFZB7C",
    },
    {
      name: "Grille EI",
      url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=97BE5S63467154&CacheId=CFZB7C",
    },
    {
      name: "AFNOR",
      url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9D9LA991832913&CacheId=CFZB7C",
    },
    {
      name: "Boite à outils",
      url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=CAND7Y13409934&CacheId=CFZB7C",
    },
    { name: "Votre AR", url: "" },
  ],
};

export default teamChapter;
