import backendErrors from "./backendErrors.js";
import { kyHttpClient } from "../../utils/http-client";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const debug = false;

const parseBackendResponse = async (response) => {
  const data = await response.json().catch((err) => {
    throw new Error(backendErrors.DEFAULT_ERROR);
  });

  if (!response.ok) {
    throw new Error(
      backendErrors[data.errorCode] || backendErrors.DEFAULT_ERROR
    );
  }
  return data;
};

export const getLogbookActivity = async (version, shopCode, year) => {
  if (debug) {
    console.log(
      `GET ${BACKEND_URL}/api/logbook/${year}/${version}/activity/${shopCode}`
    );
    return {};
  }

  const response = await kyHttpClient.get(
    `api/logbook/${year}/${version}/activity/${shopCode}`
  );

  if (!response.ok && response.status === 404)
    return { answers: [], percent: [] };
  return parseBackendResponse(response);
};

export const getMagasinByCode = async (code) => {
  const response = await kyHttpClient.get(`api/magasins/raisonSocial/${code}`);
  if (!response.ok) {
    throw new Error("Failed to fetch magasin data");
  }
  try {
    return await response.json();
  } catch (error) {
    console.error("Erreur de parsing JSON:", error);
    throw new Error("La réponse de l'API n'est pas un JSON valide");
  }
};

export const updateLogbookActivity = async (
  version,
  shopCode,
  year,
  answer,
  percent = {}
) => {
  const subActivity = {
    answers: [answer],
    percent: [percent],
  };

  if (debug) {
    console.log(
      `PATCH ${BACKEND_URL}/api/logbook/${year}/${version}/activity/${shopCode}`,
      subActivity
    );
    return {};
  }

  const response = await kyHttpClient.patch(
    `api/logbook/${year}/${version}/activity/${shopCode}`,
    {
      json: subActivity,
    }
  );

  return parseBackendResponse(response);
};

export const validateLogbookActivity = async (version, shopCode, year) => {
  const body = {
    status: "VALIDATED",
  };

  if (debug) {
    console.log(
      `PATCH ${BACKEND_URL}/api/logbook/${year}/${version}/activity/${shopCode}`,
      body
    );
    return {};
  }
  const response = await kyHttpClient.patch(
    `api/logbook/${year}/${version}/activity/${shopCode}`,
    {
      json: body,
    }
  );

  const responseJSON = await parseBackendResponse(response);

  return responseJSON;
};

export const getAllMagasins = async () =>
  kyHttpClient.get("api/magasins").json();

export const getStatusStatistiqueOfLogBook = async (callback) => {
  const data = await kyHttpClient.get("api/statistic/validated").json();
  callback(data);
  return data;
};

export const getNumberOfLogBook = async (enseigne, callback) => {
  const data = await kyHttpClient
    .get(`api/statistic/numbers/${enseigne}`)
    .json();
  callback(data);
  return data;
};

export const getPercentThemeOfLogBook = async (enseigne, callback) => {
  const data = await kyHttpClient
    .get(`api/statistic/themePercent/${enseigne}`)
    .json();
  callback(data);
  return data;
};
