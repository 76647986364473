import { useContext, useEffect } from "react";
import LogbookFormContext from "../../context/logbookForm-context";

import "./SurveyJSForm.custom.css";

import { Survey } from 'survey-react';
import 'survey-react/defaultV2.min.css';
import { StylesManager } from 'survey-react';
StylesManager.applyTheme("defaultV2");

const SurveyJSForm = (props) => {
    const { surveyModel } = useContext(LogbookFormContext);
    const { currentChapter, currentPage } = props;
    useEffect(() => {

        surveyModel.setVariable("currentChapter", currentChapter);
        surveyModel.currentPageNo = currentPage;
    }, [currentChapter, currentPage, surveyModel])

    return (
        <Survey model={surveyModel} />
    );
};

export default SurveyJSForm;
