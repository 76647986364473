import React from "react";
import { Button } from "@mui/material";

const AppButton = ({ title, className, disabled, onClick, dataCy }) => {
  return (
      <Button
          variant="contained"
          disabled={disabled}
          onClick={onClick}
          className={className}
          data-cy={dataCy}
      >
        {title}
      </Button>
  );
};

export default AppButton;
