// TODO : use models when properly defined
const skillsChapter = {
    id: "skills",
    name: "Compétences Managériales",
    description: "",
    image: "/images/chapter_skills.png",
    icon: "construction",
    progress: 70,
    color: "orange",
    position: "II",
    form: {
        pages: [
            {
                "elements": [
                    {
                        "type": "matrix",
                        "name": "skills_teamManagement",
                        "title": "Animation de l’équipe",
                        "columns": [
                            {
                                "value": 1,
                                "text": "Oui"
                            }, {
                                "value": 0,
                                "text": "Non"
                            }
                        ],
                        "rows": [
                            {
                                "value": "skills_teamManagement_brandCommunication",
                                "text": "Je relaie toutes les informations de mon enseigne à mon équipe"
                            },
                            {
                                "value": "skills_teamManagement_meetings",
                                "text": "J’organise des réunions / je fais des briefs équipe (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%204-COMMUNIQUER%20AVEC%20SON%20EQUIPE.pdf?Open' target='_blank'>Fiche outil n°4</a>)"
                            },
                            {
                                "value": "skills_teamManagement_KPI",
                                "text": "J’informe mon équipe des différents indicateurs commerciaux du magasin"
                            },
                            {
                                "value": "skills_teamManagement_actions",
                                "text": "Et je mets en place avec elle un plan d’action suivant les résultats"
                            },
                            {
                                "value": "skills_teamManagement_salaryBonus",
                                "text": "J’ai mis en place un système de primes/d’intéressement"
                            },
                            {
                                "value": "skills_teamManagement_WIISMILE",
                                "text": "J'ai mis en place une offre comité d'entreprise (ex: WIISMILE) ?"
                            },
                            {
                                "value": "skills_teamManagement_fédérateurs",
                                "text": "J'organise des évènements fédérateurs avec mon équipe ?"
                            }
                        ]
                    }
                ]
            },
            {
                "elements": [
                    {
                        "type": "matrix",
                        "name": "skills_employeeManagement",
                        "title": "Animation individuelle",
                        "columns": [
                            {
                                "value": 1,
                                "text": "Oui"
                            }, {
                                "value": 0,
                                "text": "Non"
                            }
                        ],
                        "rows": [
                            {
                                "value": "skills_employeeManagement_roles",
                                "text": "J’ai établi une hiérarchie dans mon équipe "
                            },
                            {
                                "value": "skills_employeeManagement_collaborateurs",
                                "text": "j’ai formalisé par écrit les missions de chacun de mes collaborateurs"
                            },
                            {
                                "value": "skills_employeeManagement_report",
                                "text": "Je réalise un suivi régulier et personnalisé de chaque membre de mon équipe"
                            },
                            {
                                "value": "skills_employeeManagement_interview",
                                "text": "Ce suivi individuel fait l’objet d’un entretien"
                            },
                            {
                                "value": "skills_employeeManagement_summary",
                                "text": "J’organise un bilan annuel avec chaque collaborateur à l’issu duquel un plan d’action est établi (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2015-ENTRETIEN%20ANNUEL%20D%20EVALUATION.pdf?Open' target='_blank'>Fiche outil n°15</a>)"
                            },
                            {
                                "value": "skills_employeeManagement_training",
                                "text": "Je fais un point avant et après la participation à une formation (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%2016-FORMATIONS.pdf?Open' target='_blank'>Fiche outil n°16</a>)"
                            },
                            {
                                "value": "skills_employeeManagement_tests",
                                "text": "Je contrôle régulièrement les connaissances de mes collaborateurs (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%201%20EVALUER%20SON%20COLLABORATEUR.pdf?Open' target='_blank'>Fiche outil n°1</a>)"
                            }
                        ]
                    }
                ]
            },
            {
                "elements": [
                    {
                        "type": "matrix",
                        "name": "skills_organization",
                        "title": "Mon organisation",
                        "description": "(<a href='http://mon.portail.mag/wps/myportal/optic2000' target='_blank'>Fiche outil n°25</a>)",
                        "columns": [
                            {
                                "value": 1,
                                "text": "Oui"
                            }, {
                                "value": 0,
                                "text": "Non"
                            }
                        ],
                        "rows": [
                            {
                                "value": "skills_organization_meetings",
                                "text": "Je participe aux différents points de rencontres de l’enseigne (CIR/RRT/Congrès/Silmo/Table ronde)"
                            },
                            {
                                "value": "skills_organization_substitute",
                                "text": "En mon absence, je m’assure qu’une personne compétente prend le relais"
                            },
                            {
                                "value": "skills_organization_integration",
                                "text": "J’ai mis en place un process d’intégration (<a href='https://monportail.audioptic.fr/Library2.nsf/(JSONDocumentsByID)/CAND7Y13409934/$File/FICHE%202%20BONNES%20PRATIQUES%20POUR%20UNE%20INTEGRATION.pdf?Open' target='_blank'>Fiche outil n°2</a>)"
                            },
                        ]
                    }
                ]
            },
            {
                "elements": [
                    {
                        "type": "matrixdynamic",
                        "name": "skills_actionPlan",
                        "title": "Plan d'actions",
                        "addRowText": "Ajouter une action",
                        "columns": [
                            {
                                "name": "skills_action_what",
                                "title": "Quoi",
                                "cellType": "text"
                            }, {
                                "name": "skills_action_how",
                                "title": "Comment",
                                "cellType": "text"
                            }, {
                                "name": "skills_action_who",
                                "title": "Qui",
                                "cellType": "dropdown"
                            }, {
                                "name": "skills_action_when",
                                "title": "Quand",
                                "cellType": "text",
                                "inputType": "date"
                            }
                        ],
                        "detailElements": [                         
                            {
                                "name": "skills_action_kpi",
                                "title": "Indicateur de suivi",
                                "type": "text",
                                "startWithNewLine": false
                            }
                        ],
                        "detailPanelMode": "underRow",
                        "rowCount": 1
                    }
                ]
            },
            {
                "elements": [
                    {
                        "type": "comment",
                        "name": "skills_notes",
                        "title": "Notes"
                    }
                ]
            }
        ]
    },
    toolLinks: [
        { name: "Fiches de postes", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9CRFC836533760&CacheId=CFZB7C"},
        { name: "FIDAL", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9E2KG489428244&CacheId=CFZB7C"},
        { name: "AFNOR", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/list?&FolderId=9D9LA991832913&CacheId=CFZB7C"},
        { name: "Boite à outils", url: "https://monportail.audioptic.fr/wps/myportal/optic2000/pilotagemagasin/content?&DocId=CAND7Y13409934&CacheId=CFZB7C"},
        { name: "Votre AR", url: ""}
    ]
};

export default skillsChapter;