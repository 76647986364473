import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const CardStats = ({ title, color, value=0 }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <h5>
        {title}
      </h5>
    </div>
    <Card sx={{ minWidth: 150, minHeight: 100, backgroundColor: color }}>
      <CardContent>
        <Typography variant="h3" component="div">
        {typeof value === 'number' ? value.toFixed() : 0}%
        </Typography>
      </CardContent>
    </Card>
  </div>
);

export default CardStats;
