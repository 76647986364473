export const actionPlan = {
  triggers: [
    {
      type: "runexpression",
      expression: "{team_structure} notempty or {team_structure} empty",
      runExpression: `copyMatrixValues({team_structure}, "team_structure_member", [
                  ["team_skills", "team_skills_skillMember"],
                  ["team_actionPlan", "team_action_who"],
                  ["skills_actionPlan", "skills_action_who"],
                  ["quality_actionPlan", "quality_action_who"],
                  ["shop_actionPlan", "shop_action_who"]
              ])`,
    },
  ],
  name: "quality_crm",
  showNavigationButtons: false,
  elements: [
    {
      type: "matrixdynamic",
      name: "team_actionPlan",
      title: "Plan d'actions de l'équipe",

      columns: [
        {
          name: "team_action_what",
          title: "Quoi",
          cellType: "text",
        },
        {
          name: "team_action_how",
          title: "Comment",
          cellType: "text",
        },
        {
          name: "team_action_who",
          title: "Qui",
          cellType: "text",
        },
        {
          name: "team_action_when",
          title: "Quand",
          cellType: "text",
          inputType: "date",
        },
      ],
      detailElements: [
        {
          "aria-expanded": "true",
          name: "team_action_kpi",
          title: "Indicateur de suivi",
          type: "text",
          startWithNewLine: false,
          width: "40%",
        },
        {
          startWithNewLine: false,
          type: "radiogroup",
          name: "team_action_status",
          title: "État d'avancement",
          showNoneItem: false,
          choices: ["A Faire", "En Cours", "Non Réalisé", "Réalisé", "Abandonner"],
          colCount:4,
        },
        {
          type: "comment",
          name: "team_action_comment",
          title: "Commentaire",

        },
      ],

      detailPanelMode: "underRow",
      rowCount: 3,
    },

    {
      type: "matrixdynamic",
      name: "skills_actionPlan",
      title: "Plan d'actions compétences managériales",

      columns: [
        {
          name: "skills_action_what",
          title: "Quoi",
          cellType: "text",
          attr: {
            dataCy: "skills_action_what",
          }
        },
        {
          name: "skills_action_how",
          title: "Comment",
          cellType: "text",
        },
        {
          name: "skills_action_who",
          title: "Qui",
          cellType: "text",
        },
        {
          name: "skills_action_when",
          title: "Quand",
          cellType: "text",
          inputType: "date",
        },
      ],
      detailElements: [
        {
          name: "skills_action_kpi",
          title: "Indicateur de suivi",
          type: "text",
          startWithNewLine: false,
        },
        {
          type: "radiogroup",
          name: "skills_action_status",
          title: "État d'avancement",
          showNoneItem: true,
          choices: ["A Faire", "En Cours", "Non Réalisé", "Réalisé", "Abandonner"],
        },
        {
          type: "comment",
          name: "skills_action_comment",
          title: "Commentaire",
        },
      ],
      detailPanelMode: "underRow",
      rowCount: 1,
    },
    {
      type: "matrixdynamic",
      name: "shop_actionPlan",
      title: "Plan d'actions commerce",

      columns: [
        {
          name: "shop_action_what",
          title: "Quoi",
          cellType: "text",
        },
        {
          name: "shop_action_how",
          title: "Comment",
          cellType: "text",
        },
        {
          name: "shop_action_who",
          title: "Qui",
          cellType: "text",
        },
        {
          name: "shop_action_when",
          title: "Quand",
          cellType: "text",
          inputType: "date",
        },
      ],
      detailElements: [
        {
          name: "shop_action_kpi",
          title: "Indicateur de suivi",
          type: "text",
          startWithNewLine: false,
        },
        {
          type: "radiogroup",
          name: "shop_action_status",
          title: "État d'avancement",
          showNoneItem: true,
          choices: ["A Faire", "En Cours", "Non Réalisé", "Réalisé", "Abandonner"],
        },
        {
          type: "comment",
          name: "shop_action_comment",
          title: "Commentaire",
        },
      ],
      detailPanelMode: "underRow",
      rowCount: 1,
    },
    {
      type: "matrixdynamic",
      name: "quality_actionPlan",
      title: "Plan d'actions qualité",

      columns: [
        {
          name: "quality_action_what",
          title: "Quoi",
          cellType: "text",
        },
        {
          name: "quality_action_how",
          title: "Comment",
          cellType: "text",
        },
        {
          name: "quality_action_who",
          title: "Qui",
          cellType: "text",
        },
        {
          name: "quality_action_when",
          title: "Quand",
          cellType: "text",
          inputType: "date",
        },
      ],
      detailElements: [
        {
          name: "quality_action_kpi",
          title: "Indicateur de suivi",
          type: "text",
          startWithNewLine: false,
        },
        {
          type: "radiogroup",
          name: "quality_action_status",
          title: "État d'avancement",
          showNoneItem: true,
          choices: ["A Faire", "En Cours", "Non Réalisé", "Réalisé", "Abandonner"],
        },
        {
          type: "comment",
          name: "quality_action_comment",
          title: "Commentaire",
        },
      ],
      detailPanelMode: "underRow",
      rowCount: 1,
    },
  ],
};
